import React, { useState, useEffect, useCallback } from "react";
import { View, Platform, Dimensions, Text } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import Colors from "../../constants/Colors";
import styles from "../../components/style/styles.js";
import CoreImage from "../../components/core/CoreImage";
import { AntDesign, Ionicons, FontAwesome } from "@expo/vector-icons";
import { ProgressBar } from "react-native-paper";
import CoreText from "../../components/core/CoreText";
import Modal from "react-native-modal";
import FooterHumburderMenu from "../../screens/FooterHumburderMenu";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setOrganizationSettings } from "../../components/redux/actions/prodActions";
import { getOrganisationSettingData } from "../../actions";
import { Dropdown } from "react-native-element-dropdown";
import { useTranslation } from 'react-i18next';
import { Grid, Col } from "react-native-easy-grid";

const MontserratRegular = require("../../assets/Font/Montserrat-Regular.ttf");

const Header = ({
  navigation,
  isHeader,
  progress,
  steps,
  isHomePage,
  route,
}) => {
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const { width } = Dimensions.get("window");
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = value => {
    i18n.changeLanguage(value)
      .then(() => setLanguage(value))
      .catch(err => console.log(err));
  };

  useEffect(
    useCallback(() => {
      setLanguage(i18n.language);
    }, [])
  );

  useEffect(async () => {
    const organisationId = await AsyncStorage.getItem("organisation");
    getOrganisationSettingData(organisationId)
      .then((resp) => {
        dispatch(setOrganizationSettings(resp?.data[0]));
      })
      .catch((error) => { });
  }, []);

  const toggleSideMenu = () => {
    setSideMenuVisible(true);
  };
  const orgDetail = useSelector((state) => state.prodReducer.products);

  const renderItem = item => {
    return (
      <View style={{ padding: 10, textAlign: "center", fontWeight: 500 }}>
        <Text style={{ fontSize: 14 }}>{item.label}</Text>
      </View>
    );
  };

  const dropDownMenu = (
    <Dropdown
      style={{ cursor: "pointer" }}
      iconStyle={styles.iconStyle}
      selectedTextStyle={styles.selectedTextStyle}
      containerStyle={{ width: 65 }}
      data={getLaunguage}
      labelField="label"
      valueField="value"
      mode="auto"
      placeholder={false}
      value={language}
      onChange={(item) => {
        changeLanguage(item.value);
      }}
      renderLeftIcon={() => (
        <FontAwesome
          name="globe"
          size={18}
          color="black"
          style={{ marginRight: 5 }}
        />
      )}
      renderItem={renderItem}
    />
  )
  return (
    <>
      <View>
        <Modal
          isVisible={sideMenuVisible}
          animationIn="slideInRight"
          animationOut="slideOutRight"
          animationInTiming={500}
          animationOutTiming={500}
          backdropOpacity={0.6}
          onBackdropPress={() => setSideMenuVisible(false)}
          style={{
            marginRight: 0,
            marginLeft: "auto",
            marginVertical: 0,
            width: width * 0.8,
            backgroundColor: "#fff",
          }}
        >
          <FooterHumburderMenu
            setSideMenuVisible={setSideMenuVisible}
            navigation={navigation}
            route={route}
          />
        </Modal>
      </View>
      <View
        style={
          Platform?.OS === "web"
            ? {
              position: "sticky",
              top: 0,
              right: 0,
              left: 0,
              backgroundColor:
                orgDetail?.[0]?.theme?.headerTopBgColor || "#F9F6F1",
              zIndex: 10,
              borderBottomWidth: 1,
              borderBottomColor: "rgba(58, 33, 4, 0.2)",
            }
            : {
              backgroundColor:
                orgDetail?.[0]?.theme?.headerTopBgColor || "#F9F6F1",
              borderBottomWidth: 1,
              borderBottomColor: "rgba(58, 33, 4, 0.2)",
            }
        }
      >
        {isHeader ? (
          <Grid style={{ paddingVertical: 10, paddingHorizontal: 20, alignItems: "center" }}>
            <Col size={6}>
              {isHomePage ? (
                <View />
              ) : (
                <AntDesign
                  name="left"
                  size={20}
                  color={Colors.BLACK}
                  onPress={() => navigation.goBack()}
                />
              )}
            </Col>
            <Col size={12}>
              <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
            </Col>
            <Col size={6}>
              <View style={{ flexDirection: "row", alignItems: "center", gap: 15, justifyContent: "flex-end" }}>
                {dropDownMenu}
                <Ionicons
                  name="ios-menu-sharp"
                  size={26}
                  color="black"
                  onPress={toggleSideMenu}
                />
              </View>
            </Col>
          </Grid>
        ) : (<Grid style={{ paddingVertical: 31, paddingHorizontal: 20, alignItems: "center" }}>
          <Col size={6}>
            <AntDesign
              name="left"
              size={20}
              color={Colors.BLACK}
              onPress={() => navigation.goBack()}
            />
          </Col>
          <Col size={12}>
            <View style={[styles.progressContainer, styles.FlexContainer]}>
              <ProgressBar
                progress={progress}
                color={orgDetail?.[0]?.primaryColor || Colors.BROWN}
                style={[styles.progress]}
              />
            </View>
          </Col>
          <Col size={6}>
            <View style={{ flexDirection: "row", alignItems: "center", gap: 15, justifyContent: "flex-end" }}>
              {dropDownMenu}
              <CoreText
                textTitle={`${steps} av 6`}
                style={[styles.progressSteps]}
                fontType={MontserratRegular}
              />
            </View>
          </Col>
        </Grid>)}
      </View>
    </>
  );
};

export default Header;

const getLaunguage = [
  {
    label: "SW",
    value: "sw",
  },
  {
    label: "EN",
    value: "en",
  },
];