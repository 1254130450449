import React, { useState, useEffect } from "react";
import { Text } from "react-native";
import * as Font from "expo-font";

const CoreText = ({
  fontType,
  textTitle,
  style,
  isFrom,
  iconFront,
  iconBack,
  FirstTitle,
  lastTitle,
  icon,
  bulletPoint,
  textValue,
  dropOffItem,
  isInput,
}) => {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    const loadFont = async () => {
      await Font.loadAsync({
        Montserrat: fontType,
      });
      setFontLoaded(true);
    };
    loadFont();
  }, []);

  //clean-up
  useEffect(
    () => () => {
      setFontLoaded(false);
    },
    []
  );

  if (!fontLoaded) {
    return null;
  } else {
    return isFrom ? (
      <Text style={[style, { fontFamily: "Montserrat" }]} numberOfLines={2}>
        {textTitle}
      </Text>
    ) : textValue ? (
      <Text style={[style, { fontFamily: "Montserrat" }]} numberOfLines={2}>
        {lastTitle}
      </Text>
    ) : dropOffItem ? (
      <Text style={[style, { fontFamily: "Montserrat" }]} numberOfLines={2}>
        {FirstTitle} {icon} {lastTitle}
      </Text>
    ) : (
      <Text
        style={[style, { fontFamily: "Montserrat" }]}
        numberOfLines={isInput ? 1 : 2}
      >
        {iconFront}
        {bulletPoint}
        {textTitle}
        {iconBack}
      </Text>
    );
  }
};

export default CoreText;
