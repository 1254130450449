import React from "react";
import Barcode from "react-barcode";
import moment from "moment";
import { Text } from "react-native";
import jsPDF from "jspdf";
import ReactDOM from "react-dom";

const PrintBarcode = (sourceItem, barcodeSettingObject,orgSetting) => {
  const barcodeData = barcodeSettingObject?.barcode_setting?.split(",");

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentWeekNumber = moment().isoWeek();

  const brand = sourceItem?.brand && (
    <Text>{sourceItem?.brand}</Text>
  );
  const category = sourceItem?.category && (
    <Text>{sourceItem?.category}</Text>
  );
  const vat = sourceItem?.vat && <Text>{sourceItem?.vat}</Text>;
  const description = sourceItem?.description && (
    <Text>{sourceItem?.description}</Text>
  );
  const month = <Text>{currentMonth}</Text>;
  const week = <Text>{currentWeekNumber}</Text>;

  const allFields = {
    Brand: brand,
    Category: category,
    Vat: vat,
    Description: description,
    Month: month,
    Week: week,
    None: "",
  };

  const _width = barcodeSettingObject?.barcodePage === "l" ? barcodeSettingObject?.height || 80 : barcodeSettingObject?.width || 100;
  const _height = barcodeSettingObject?.barcodePage === "l" ? barcodeSettingObject?.width || 100 : barcodeSettingObject?.height || 80;

  const doc = new jsPDF(barcodeSettingObject?.barcodePage || "l", "mm", [ _height,_width ]);

  let lineHeight = barcodeSettingObject?.lineHeight || 2;
  let StartingPoint = barcodeSettingObject?.startingPoint || 5;

  doc.setFontSize(11);

  let lineHeightIncrement = (barcodeSettingObject?.fontSize || 10) / 2;
  lineHeightIncrement = lineHeightIncrement * .8;

  const svgContainer = document.createElement("div");
  const barcodeElement = (
    <Barcode
      value={sourceItem?.sku}
      displayValue={false}
    />
  );
  ReactDOM.render(barcodeElement, svgContainer);
  const svg = svgContainer.querySelector("svg");

  if (sourceItem?.sku) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const svgData = new XMLSerializer().serializeToString(svg);
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL("image/png");

      doc.addImage(dataUrl,"PNG", StartingPoint - 3, lineHeight,60, barcodeSettingObject?.barcodeHeight || 15);
      lineHeight += (barcodeSettingObject?.barcodeHeight || 15) + 3;

      const textValue = sourceItem?.sku;
      doc.text(textValue, StartingPoint, lineHeight);
      lineHeight += lineHeightIncrement;

      doc.setFontSize(barcodeSettingObject?.fontSize || 11);
      if (sourceItem?.price > 0) {
        doc.text(`Pris: ${sourceItem?.price} ${orgSetting?.currency || 'kr'}`, StartingPoint, lineHeight);
        lineHeight += lineHeightIncrement;
      }
      doc.setFontSize(11);
      barcodeData?.forEach((item) => {
        if (allFields[item]) {
          const fieldContent = allFields[item]?.props?.children;
          const fieldText = Array.isArray(fieldContent)
            ? fieldContent.join(" ")
            : String(fieldContent);
          let line = doc
            .splitTextToSize(
              fieldText.replaceAll("\n", " "),
              _width - StartingPoint - 5
            )
            .slice(0, 3);
          doc.text(line.join(`\n`), StartingPoint, lineHeight);
          lineHeight += line.length * 5;
        }
      });

      doc.autoPrint();
      const pdfUrl = doc.output("bloburl");
      window.open(pdfUrl, "_blank");
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  }
};

export default PrintBarcode;
