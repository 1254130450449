import React, { useEffect, useState } from "react";
import { View, Platform, SafeAreaView, StatusBar } from "react-native";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import { useSelector, useDispatch } from "react-redux";
import CoreLoader from "../components/core/CoreLoader";
import { setOrganizationSettings } from "../components/redux/actions/prodActions";
import { getOrganisationSettingData } from "../actions";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const FaqPage = ({ navigation, route }) => {
    const [isLoad, setIsLoad] = useState(true);
    const organisationId = route.params.organisationId;
    const dispatch = useDispatch();

    useEffect(async () => {
        await getOrganisationSettingData(organisationId).then((resp) => {
            dispatch(setOrganizationSettings(resp?.data[0]));
            setIsLoad(false);
        });
    }, []);

    const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
    const FaqList = orgSetting?.faq;
    return isLoad ? (
        <CoreLoader
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        />
    ) : (
        <SafeAreaView
            style={{
                flex: 1,
                paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
            }}
        >
            <Header navigation={navigation} route={route} isHeader />
            <View style={[styles.Landingcontainer, { paddingHorizontal: 10 }]}>
                <CoreText
                    textTitle="FAQ"
                    style={[styles.titleHeader, { fontSize: 26, fontWeight: "bold" }]}
                    fontType={MontserratMedium}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: `${FaqList}`,
                    }}
                ></div>
            </View>
            <CoreFooter />
        </SafeAreaView>
    );
};
export default FaqPage;
