import axios from "axios";
import config from "./config";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { AsyncStorage } from 'react-native';
// import "localstorage-polyfill";

const axiosApi = axios.create({
  baseURL: config.API_URL,
});

export const setAuthHeader = async (token) => {
  const _token = token || (await AsyncStorage.getItem("token"));
  axiosApi.defaults.headers.common.Authorization = `${token}` || _token;
};

// axiosApi.defaults.headers.common.Authorization = `${AsyncStorage.getItem(
//   "token"
// )}`;

//if a 401 happens, the user will be logged out
axiosApi.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error && error.response && error.response.status === 401) {
      await AsyncStorage.removeItem("token");
      await AsyncStorage.removeItem("user");
      await AsyncStorage.removeItem("admin");
      //window.location.replace("/");
    }
    return Promise.reject(error);
  }
);

// Set the initial header from storage or something (should surround with try catch in actual app)
// const _token = AsyncStorage.getItem("token");
setAuthHeader();

export default axiosApi;
