export const ArticleStatus = {
  // Describes the status of the physical artile
  AtCustomer: 1, // The item is waiting to be sent
  WaitingToBeSent: 2, // The item is waiting to be sent
  InTransport: 3, // The item is in transfer to the merchent
  IsAccepted: 4, // The item has been accepted by the merchent when it arrived
  IsRejected: 5, // Them item has been rejected by the merchent when it arrived
};

export const Status = {
  // Describes the status between the merchent (organisation) and the customer of the item
  Pending: 1, // Waiting on merchent to accept
  Accepted: 2, // It's accepted by the mercehnt
  RejectedByMerchent: 3, // Rejeected by merchent
  CounterOffer: 4, // There is a counter offer
  RejectedByCustomer: 5, // Counter offer is rejected
  AcceptedByCustomer: 6, // Counter offer is rejected
};

export const ReadyToSaleStatus = {
  // Describes the status of the item in a commerce context, it's either sold or not
  InProcess: 1,
  IsReadyToBeSold: 2,
  Sold: 3,
  Lost: 4,
  Expired: 5,
};
