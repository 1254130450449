import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import { useSelector } from "react-redux";
import styles from "../components/style/styles.js";
import { TextInput } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import Header from "../components/core/Header";
import {
  ArticleStatus,
  Status,
  ReadyToSaleStatus,
} from "../sharedLib/sourcingItemStatus.js";
import RNPickerSelect from "react-native-picker-select";
import CoreLoader from "../components/core/CoreLoader";
import { useTranslation } from "react-i18next";
import PrintBarcode from "./barcode";
import {
  getCategory,
  putProductDetails,
  getProductDetailsById1,
} from "../actions";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const CreateSourceItem = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickOrg = route.params.organisationId;
  const [isLoad, setIsLoad] = useState(false);
  const [catogaryData, setCatogaryData] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [colorListData, setColorListData] = useState([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const [barcodeSettingObject, setBarcodeSettingObject] = useState({});

  useEffect(() => {
    if ((/^[\],:{}\s]*$/.test(orgSetting?.barcode_setting?.replace(/\\["\\\/bfnrtu]/g, '@').
      replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
      replace(/(?:^|:|,)(?:\s*\[)+/g, '')))) {
      setBarcodeSettingObject(JSON.parse(orgSetting?.barcode_setting) || {});
    } else {
      setBarcodeSettingObject({
        barcode_setting: orgSetting?.barcode_setting
      });
    }
  }, [orgSetting]);

  const getCustomer = {
    email: customers?.email || customers?.[0]?.email,
    id: customers?.id,
  };

  const defaultValue = {
    photo: [],
    organisation_id: pickOrg,
    gender: "",
    status: Status.Pending,
    article_status: ArticleStatus.AtCustomer,
    sale_status: ReadyToSaleStatus.InProcess,
    brand: "",
    customer_details: getCustomer,
    customer_id: customers?.id || customers?.[0]?.id,
    reseller_id: customers?.id || customers?.[0]?.id,
    url: "",
    approved: "",
    offer_status: "offer_pending",
    price_interval: "",
    category: "",
    price: null,
    location_id: "",
    type: "",
    description: "",
    color: "",
    vat: orgSetting?.vat,
    flat_fee: orgSetting?.flat_fee || 0,
    expire_day: orgSetting?.expire_day || 0,
  };

  const [sourceItem, setSourceItem] = useState(defaultValue);

  useEffect(() => {
    if (orgSetting) {
      const data = orgSetting?.brand ? JSON.parse(orgSetting?.brand) : [];
      const allBrands = data?.[0]?.split(",") || [];
      const formattedList = allBrands?.map((brand, index) => ({
        label: brand,
        value: brand,
      }));

      setBrandList(formattedList);

      const colorData = orgSetting?.color ? JSON.parse(orgSetting?.color) : [];
      const allColors = colorData?.[0]?.split(",") || [];
      const colorList = allColors?.map((color, index) => ({
        label: color,
        value: color,
      }));

      setColorListData(colorList);
    }
  }, [orgSetting]);

  useEffect(async () => {
    setIsLoad(true);
    await getCategory()
      .then((resp) => {
        let _catogary = resp?.data?.map((item) => ({
          label: item?.brand,
          value: item?.brand,
        }));
        setCatogaryData(_catogary);
        setIsLoad(false);
      })
      .catch((error) => {
        setIsLoad(false);
      });
  }, []);

  const handleClick = async (print) => {
    if (print) {
      setIsLoadingPrint(true);
    } else {
      setIsLoadingSave(true);
    }
    let data = {
      ...sourceItem,
    };
    await putProductDetails(data, customers?.isLogin).then((resp) => {
      getProductDetailsById1(resp?.data).then(({ data }) => {
        if (print) {
          PrintBarcode(data, barcodeSettingObject, orgSetting);
        }
        if ((customers?.isLogin || customers?.[0]?.isLogin) === true) {
          navigation.navigate("AddItemSuccess", {
            organisationId: pickOrg,
          });
        }
        setSourceItem(defaultValue);
      });
      if (print) {
        setIsLoadingPrint(false);
      } else {
        setIsLoadingSave(false);
      }
    });
  };

  return isLoad ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <Header navigation={navigation} route={route} isHeader />
        <ScrollView>
          <View style={[styles.Landingcontainer]}>
            <View style={[styles.alignItems, styles.justifyContent]}>
              <View style={{ paddingHorizontal: 20 }}>
                <CoreText
                  textTitle={t("app.create_new_item", "Skapa en ny artikel")}
                  style={styles.sourceItemTitle}
                  fontType={MontserratMedium}
                />
              </View>
            </View>
          </View>
          <View style={[styles.btnsection, { marginTop: 16 }]}>
            <View style={{ marginBottom: 15 }}>
              <TextInput
                label={
                  <CoreText
                    textTitle="Desctiption"
                    style={[styles.input]}
                    fontType={MontserratRegular}
                    isInput
                  />
                }
                value={sourceItem?.description}
                style={[styles.inputFiledstyle]}
                keyboardType="default"
                placeholderTextColor="#878787"
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                maxLength={200}
                onChangeText={(value) => {
                  setSourceItem({ ...sourceItem, description: value });
                }}
              />
            </View>
            <View style={{ marginBottom: 15 }}>
              <TextInput
                label={
                  <CoreText
                    textTitle="Price"
                    style={[styles.input]}
                    fontType={MontserratRegular}
                    isInput
                  />
                }
                // value={sourceItem?.price}
                style={[styles.inputFiledstyle]}
                keyboardType="numeric"
                placeholderTextColor="#878787"
                underlineColor="transparent"
                activeUnderlineColor="transparent"
                onChangeText={(value) => {
                  setSourceItem({ ...sourceItem, price: parseInt(value) });
                }}
              />
            </View>
            <View style={{ marginBottom: 15 }}>
              <RNPickerSelect
                placeholder={{
                  label: "Select Category",
                }}
                items={catogaryData}
                onValueChange={(value) =>
                  setSourceItem({ ...sourceItem, category: value })
                }
                // value={sourceItem?.category}
                useNativeAndroidPickerStyle={false}
                pickerProps={{
                  style: {
                    padding: 20,
                    fontSize: 15,
                    borderWidth: 1,
                    borderColor: "#E6E6E6",
                    borderRadius: 15,
                  },
                }}
              />
            </View>
            <View style={{ marginBottom: 15 }}>
              <RNPickerSelect
                placeholder={{
                  label: "Select Brand",
                }}
                items={brandList}
                onValueChange={(value) =>
                  setSourceItem({ ...sourceItem, brand: value })
                }
                // value={sourceItem?.brand}
                useNativeAndroidPickerStyle={false}
                pickerProps={{
                  style: {
                    padding: 20,
                    fontSize: 15,
                    borderWidth: 1,
                    borderColor: "#E6E6E6",
                    borderRadius: 15,
                  },
                }}
              />
            </View>
            <View style={{ marginBottom: 15 }}>
              <RNPickerSelect
                placeholder={{
                  label: "Select Color",
                }}
                items={colorListData}
                onValueChange={(value) =>
                  setSourceItem({ ...sourceItem, color: value })
                }
                // value={sourceItem?.color}
                useNativeAndroidPickerStyle={false}
                pickerProps={{
                  style: {
                    padding: 20,
                    fontSize: 15,
                    borderWidth: 1,
                    borderColor: "#E6E6E6",
                    borderRadius: 15,
                  },
                }}
              />
            </View>
          </View>
        </ScrollView>
        <View style={{ gap: 10, padding: 20 }}>
          <Button
            btnTitle={t("app.save", "Jag är hemma, spara och gå vidare")}
            isLoad={isLoadingSave}
            disabled={!sourceItem?.price}
            disableButtondata={!sourceItem?.price}
            onPress={() => handleClick(false)}
            textstyle={
              sourceItem?.price ? styles.btntext : styles.disableBtnStyle
            }
            style={{ flex: 1 }}
          />
          <Button
            btnTitle={t("app.save_print", "Jag är i butiken, skriv ut en streckkod och spara")}
            isLoad={isLoadingPrint}
            disabled={!sourceItem?.price}
            disableButtondata={!sourceItem?.price}
            onPress={() => handleClick(true)}
            textstyle={
              sourceItem?.price ? styles.btntext : styles.disableBtnStyle
            }
            style={{ flex: 1 }}
          />
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default CreateSourceItem;
