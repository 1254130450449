import axiosApi from "./axiosApi";

export const getOrganisationById = (id) => {
  return axiosApi({
    method: "GET",
    url: `/organisation/${id}`,
  });
};

export const getCategory = () => {
  return axiosApi({
    method: "GET",
    url: "/categories",
  });
};


// Simon: Implementing, don't touch :P
export const loginByEmailIntegration = (email) => {
  return axiosApi({
    method: "POST",
    url: "https://xy2z02xaij.execute-api.eu-west-1.amazonaws.com/dev/api/LoginByEmailStep1",
    data: { email: email },
  });
};

export const loginByEmailIntegrationStep2 = (email, onetimeCode, password) => {
  return axiosApi({
    method: "POST",
    url: "https://xy2z02xaij.execute-api.eu-west-1.amazonaws.com/dev/api/LoginByEmailStep2",
    data: { email: email, onetimeCode: onetimeCode, password: password },
  });
};

// Simon: Implementing, don't touch :P
export const getComission = () => {
  return axiosApi({
    method: "POST",
    url: "https://xy2z02xaij.execute-api.eu-west-1.amazonaws.com/dev/api/getComission",
    data: {},
  });
};

// Simon: Implementing, don't touch :P
export const getCustomerDetails = () => {
  return axiosApi({
    method: "POST",
    url: "https://xy2z02xaij.execute-api.eu-west-1.amazonaws.com/dev/api/getCustomerDetails",
    data: {},
  });
};

// Simon: Implementing, don't touch :P
export const getArticlesByCustomerId = () => {
  return axiosApi({
    method: "POST",
    url: "https://xy2z02xaij.execute-api.eu-west-1.amazonaws.com/dev/api/getArticlesByCustomerId",
    data: {},
  });
};

// Simon: Implementing, don't touch :P
export const adjustComission = (amount) => {
  return axiosApi({
    method: "POST",
    url: "https://xy2z02xaij.execute-api.eu-west-1.amazonaws.com/dev/api/adjustComission",
    data: { amount: amount },
  });
};

// Simon: Implementing, don't touch :P
export const startComissionflow = (ssn) => {
  return axiosApi({
    method: "POST",
    url: "https://oy3niol6e5.execute-api.eu-west-1.amazonaws.com/startPayoutFlow",
    data: { ssn: ssn },
  });
};

export const getProductDetails = (orgName) => {
  return axiosApi({
    method: "GET",
    url: `api/my-sourcing-item?organisation=${orgName}`,
  });
};

export const selfServiceFlowUpdate = (data, orgName) => {
  return axiosApi({
    method: "PUT",
    data,
    url: `api/my-sourcing-item?organisation=${orgName}`,
  });
};

export const getProductDetailsById = (id) => {
  return axiosApi({
    method: "GET",
    url: `sourcingItemByOrg/${id}`,
  });
};
export const getProductDetailsById1 = (id) => {
  return axiosApi({
    method: "GET",
    url: `api/sourcing-item/${id}`,
  });
};

export const putProductDetails = (data, loggedOn) => {
  if (loggedOn == true) {
    return axiosApi({
      method: "POST",
      url: "api/my-sourcing-item",
      data,
    });
  } else {
    return axiosApi({
      method: "POST",
      url: "api/sourcing-item",
      data,
    });
  }
};

export const isEmailExist = (email, organisation) => {
  return axiosApi({
    method: "GET",
    url: `customers/by-attributes?email=${email}&organisation=${organisation}`,
  });
};

export const myDetails = (organisation) => {
  return axiosApi({
    method: "GET",
    url: `customers/me?organisation=${organisation}`,
  });
};

export const editProductDetails = (id, data) => {
  return axiosApi({
    method: "PATCH",
    url: `api/sourcing-item/${id}`,
    data,
  });
};

export const postProductDetails = (data) => {
  return axiosApi({
    method: "POST",
    url: "/api/image-upload",
    data,
  });
};

export const findservicePointbyPostalCode = (postalCode) => {
  return axiosApi({
    method: "POST",
    url: `api/find-service-point`,
    data: { postalCode },
  });
};

export const nearestServicePoint = ({ latitude, longitude }) => {
  return axiosApi({
    method: "POST",
    url: `api/nearest-service-point`,
    data: { latitude, longitude },
  });
};

export const createCustomer = (data) => {
  return axiosApi({
    method: "POST",
    url: "/customers",
    data,
  });
};

export const createCustomerHashCode = (data) => {
  return axiosApi({
    method: "POST",
    url: "/customers/hashCode",
    data,
  });
};
export const getCustomerHashCode = (pickOrg, emailValue, hashCode) => {
  const email = encodeURIComponent(emailValue)
  return axiosApi({
    method: "GET",
    url: `/customers/hashCode?organisationId=${pickOrg}&email=${email}&hashCode=${hashCode}`,
  });
};

export const linkItemWithUser = (id) => {
  return axiosApi({
    method: "PUT",
    url: `api/sourcing-item-update-customer-id/${id}`,
  });
};

export const generateLogisticInstructions = (id) => {
  return axiosApi({
    method: "POST",
    url: `api/generate-logistic-instructions-by-id/${id}`,
  });
};

export const engageCustomer = (organisationId, email) => {
  return axiosApi({
    method: "POST",
    url: `/customers/engage`,
    data: { organisationId: organisationId, email: email },
  });
};

export const firstLogin = (
  organisationId,
  email,
  password,
  hashCode,
  fullname
) => {
  return axiosApi({
    method: "POST",
    url: `/customers/firstlogin`,
    data: {
      organisationId: organisationId,
      email: email,
      password,
      hashCode,
      fullname,
    },
  });
};

export const getPoolByOrganisationId = (id) => {
  return axiosApi({
    method: "GET",
    url: `/organisationpoolid/${id}`,
  });
};

// ============ CommissionWithdraw ===============

export const addCustomerAccountDetail = (data) => {
  return axiosApi({
    method: "PUT",
    url: `/customers/account-detail`,
    data,
  });
};

export const addTransactionData = (data, type) => {
  return axiosApi({
    method: "POST",
    url: `${type ? `/transaction/me?type=${type}` : `/transaction/me`}`,
    data,
  });
};


export const getOrganisationSettingData = (id) => {
  return axiosApi({
    method: "GET",
    url: `/organisation-setting/public?organisation=${id}`,
  });
};

export const adjustComissionData = (data, id) => {
  return axiosApi({
    method: "POST",
    url: `/adjustComission?organisation=${id}`,
    data
  });
};


// chat message
export const addChatMessages = (data) => {
  return axiosApi({
    method: "POST",
    url: `/chat-messages`,
    data
  });
};

export const getChatMessagesId = (orgId) => {
  return axiosApi({
    method: "GET",
    url: `/chat-messages?organisation=${orgId}`,
  });
};

export const getLanguagesList = () => {
  return axiosApi({
    method: "GET",
    url: `/organisation-setting/public?lang=ture`,
  });
};