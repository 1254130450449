import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import { useSelector } from "react-redux";
import { Card, TouchableRipple } from "react-native-paper";
import CoreText from "../components/core/CoreText";
import styles from "../components/style/styles";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const addAllItems = [
  {
    cardtitle: "Presentkort",
    Paragraph: "800 SEK i alla våra butiker",
  },
  {
    cardtitle: "Begär utbetalning",
    Paragraph: "750 SEK",
  },
];

const ItemSoid = ({ navigation, route }) => {
  const cartImage = route.params.cartImage;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.itemsoidcontainer}>
          <View style={styles.itemproductImage}>
            <Image
              style={styles.itemproductImage}
              source={{ uri: cartImage }}
            />
            <Image
              style={styles.celebrateImage}
              source={require("../assets/images/celebrateIcon.png")}
            />
            <View style={styles.imgtext}>
              <Text style={styles.itemsoid}>
                <CoreText textTitle="800 " fontType={MontserratMedium} />
                <CoreText
                  textTitle="sek"
                  fontType={MontserratMedium}
                  style={styles.sektext}
                />
              </Text>
            </View>
          </View>
          <View style={styles.optionBody}>
            <View style={styles.textBody}>
              <CoreText
                textTitle="Ditt plagg har sålts!"
                style={styles.titleHeader}
                fontType={MontserratMedium}
              />
              <CoreText
                textTitle="Välj vilket sätt du vill få utbetalningssätt."
                style={styles.itemsubTitle}
                fontType={MontserratRegular}
              />
            </View>
          </View>
          <View style={{ marginTop: 83 }}>
            {addAllItems.map((item, index) => (
              <Card
                style={{ borderRadius: 12, shadowColor: "transparent" }}
                key={index}
              >
                <TouchableRipple
                  onPress={() => navigation.navigate("Collection")}
                  style={styles.itemMaincard}
                  rippleColor="rgba(0, 0, 0, .32)"
                >
                  <Card.Content style={styles.cardContent}>
                    <CoreText
                      textTitle={item.cardtitle}
                      style={[styles.cardTitle, styles.carddropitem]}
                      fontType={MontserratBold}
                    />
                    <CoreText
                      textTitle={item.Paragraph}
                      style={styles.CardParagraph}
                      fontType={MontserratRegular}
                    />
                  </Card.Content>
                </TouchableRipple>
              </Card>
            ))}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default ItemSoid;
