import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { setCustomer } from "../components/redux/actions/prodActions";
import { linkItemWithUser } from "../actions";
import styles from "../components/style/styles.js";
import { Provider, Portal, TextInput, Modal } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import CoreBanner from "../components/core/CoreBanner";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import { setAuthHeader } from "../axiosApi";
import CoreLoader from "../components/core/CoreLoader";
import Colors from "../constants/Colors";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const Login = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickUser = route.params.organisationId;
  const getItem = route.params.sourcedItemId;
  const customerDetail = useSelector(
    (state) => state.prodReducer.customerDetail
  );
  const exixstUser = customerDetail?.alreadyUser && customerDetail?.email;
  const [text, setText] = useState(exixstUser);
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadCustomer, setIsLoadCustomer] = useState(false);
  const [erroMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [nextBtnError, setNextBtnError] = useState("");
  const re = /^[0-9\b]+$/;
  // support '+' oprator
  const reg =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // not support '+' oprator
  // const reg =
  // /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\+[0-9]{12})+$/;

  const [visible, setVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true);

  const orgDetail = useSelector((state) => state.prodReducer.products);
  const dispatch = useDispatch();

  const poolData = useSelector((state) => state.prodReducer.userPool);
  const userPool = poolData;

  // forgetPassword
  const [createPassword, setCreatePassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [stage, setStage] = useState(1);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMsg, setCodeErrorMsg] = useState("");
  const [createPasswordVisible, setCreatePasswordVisible] = useState(true);
  const [newPasswordVisible, setNewPasswordVisible] = useState(true);

  const showModal = () => {
    setVisible(true);
    setEmail("");
    setCode("");
    setCodeError(false);
    setCodeErrorMsg("");
    setCreatePassword("");
    setNewPassword("");
    setStage(1);
  };
  const hideModal = () => setVisible(false);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const congnitoUser = new CognitoUser(userData);

  const sendCode = () => {
    if (!reg.test(email.split(" ").join(""))) {
      setCodeError(true);
      setCodeErrorMsg("Ange en giltigt epost");
    } else {
      congnitoUser.forgotPassword({
        onSuccess: (data) => {
          console.log("onSuccess:", data);
        },
        onFailure: (err) => {
          console.log("onFailure:", err?.message);
          setCodeError(true);
          setCodeErrorMsg(err?.message);
        },
        inputVerificationCode: (data) => {
          setCodeError(false);
          setCodeErrorMsg("");
          setStage(2);
        },
      });
    }
  };

  const resetPassword = () => {
    if (createPassword !== newPassword) {
      setCodeError(true);
      setCodeErrorMsg("Lösenordet är inte samma");
      return;
    }

    congnitoUser.confirmPassword(code, createPassword, {
      onSuccess: (data) => {
        setCodeError(false);
        setCodeErrorMsg("");
        hideModal();
      },
      onFailure: (err) => {
        if (code === "") {
          setCodeError(true);
          setCodeErrorMsg("Ange engångskoden");
        } else {
          setCodeError(true);
          setCodeErrorMsg(err?.message);
        }
      },
    });
  };

  const onChange = (value) => {
    if (!value) {
      setIsError(true);
      setText("");
    } else {
      setIsError(false);
      if (re.test(value)) {
        const numberFormat = value.replace(
          /^(.{3})(.{3})(.{2})(.{2})$/,
          "$1 $2 $3 $4"
        );
        setText(numberFormat);
      } else {
        setText(value);
      }
    }
  };

  useEffect(() => {
    if (text === "" || text === undefined) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, []);

  useEffect(async () => {
    if (orgDetail) {
      setIsLoad(false);
    } else {
      setIsLoad(true);
    }
  }, []);

  const handleNext = async (username, password) => {
    setIsLoadCustomer(true);
    if (username === "") {
      setIsError(true);
      setErrorMsg("Ange epost eller telefonnummer");
      setIsLoadCustomer(false);
    } else if (!reg.test(username.split(" ").join(""))) {
      setIsError(true);
      setErrorMsg("Ange ett giltigt epost eller telefonnummer");
      setIsLoadCustomer(false);
    } else {
      setErrorMsg("");
      const authenticationData = {
        Username: username.trim(),
        Password: password,
      };
      const authDetails = new AuthenticationDetails(authenticationData);
      const userData = {
        Username: username.trim(),
        Pool: userPool,
      };

      const congnitoUser = new CognitoUser(userData);

      congnitoUser.authenticateUser(authDetails, {
        onSuccess(result = CognitoUserSession) {
          if (result) {
            AsyncStorage.setItem("user", JSON.stringify({ ...result }));
            AsyncStorage.setItem("token", result?.idToken?.jwtToken);
            const getUserInfo = result?.idToken?.payload;
            setAuthHeader(result?.idToken?.jwtToken);

            const customerData = {
              email: getUserInfo?.email,
              phoneNumber: getUserInfo?.phone_number,
              id: getUserInfo?.sub,
              isLogin: true,
            };
            dispatch(setCustomer(customerData));
            AsyncStorage.setItem("customer", JSON.stringify(customerData));
            setTimeout(() => {
              if (getItem) {
                linkItemWithUser(getItem);
                navigation.navigate("AddItemSuccess", {
                  organisationId: pickUser,
                });
              } else {
                navigation.navigate("SoldClothBagCollection", {
                  organisationId: pickUser,
                });
              }
            }, 1000);
            setIsLoadCustomer(false);
          }
        },
        onFailure(err) {
          const { message } = err;
          setNextBtnError(message);
          setIsLoadCustomer(false);
        },
      });
      // }
    }
  };

  // submit data whe we press enter
  const handleKeyPress = (keyName) => {
    if (keyName === "Enter") {
      handleNext(text, password);
    }
  };

  return isLoad ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <Provider>
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <Header navigation={navigation} route={route} isHeader />
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
        >
          <ScrollView>
            <Portal>
              <Modal
                visible={visible}
                onDismiss={hideModal}
                contentContainerStyle={{
                  backgroundColor: "white",
                  padding: 20,
                  marginVertical: 0,
                  marginHorizontal: 15,
                  borderRadius: 10,
                }}
              >
                {stage === 1 && (
                  <>
                    <View style={{ marginBottom: 10, marginHorizontal: 20 }}>
                      <TextInput
                        label={
                          <CoreText
                            textTitle={t("app.email", "Epost")}
                            style={[styles.input]}
                            fontType={MontserratRegular}
                            isInput
                          />
                        }
                        style={[styles.inputFiledstyle]}
                        value={email}
                        keyboardType="default"
                        placeholderTextColor="#878787"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        onChangeText={(text) => {
                          setEmail(text);
                          setCodeError(false);
                        }}
                        theme={{ colors: { text: Colors.BLACK } }}
                      />
                      {codeError && (
                        <CoreText
                          textTitle={codeError && codeErrorMsg}
                          style={[codeError && styles.error]}
                          fontType={MontserratRegular}
                        />
                      )}
                    </View>
                    <View style={[styles.btnsection, { marginTop: 10 }]}>
                      <Button
                        btnTitle={t("app.code", "Skicka återställningskod")}
                        isError={codeError}
                        disableButtondata={codeError || !email}
                        disabled={codeError || !email}
                        textstyle={[
                          !codeError
                            ? styles.btntextstyle
                            : styles.disableBtnStyle,
                          { lineHeight: 36, marginTop: 0 },
                        ]}
                        onPress={() => sendCode(email, newPassword)}
                      />
                    </View>
                  </>
                )}
                {stage === 2 && (
                  <>
                    <View style={{ marginHorizontal: 20 }}>
                      <View style={{ marginBottom: 10 }}>
                        <TextInput
                          label={
                            <CoreText
                              textTitle={t("app.enterVerifyCode", "Fyll i återställningskod")}
                              style={[styles.input]}
                              fontType={MontserratRegular}
                              isInput
                            />
                          }
                          style={[styles.inputFiledstyle]}
                          value={code}
                          keyboardType="default"
                          placeholderTextColor="#878787"
                          underlineColor="transparent"
                          activeUnderlineColor="transparent"
                          onChangeText={(text) => {
                            setCode(text);
                            setCodeError(false);
                          }}
                          theme={{ colors: { text: Colors.BLACK } }}
                        />
                      </View>
                      <View style={{ marginBottom: 10 }}>
                        <TextInput
                          label={
                            <CoreText
                              textTitle={t("app.newPassword", "Nytt lösenord")}
                              style={[styles.input]}
                              fontType={MontserratRegular}
                              isInput
                            />
                          }
                          secureTextEntry={newPasswordVisible}
                          right={
                            <TextInput.Icon
                              name={newPasswordVisible ? "eye-off" : "eye"}
                              onPress={() =>
                                setNewPasswordVisible(!newPasswordVisible)
                              }
                              theme={{ colors: { text: Colors.BLACK } }}
                            />
                          }
                          style={[styles.inputFiledstyle]}
                          value={createPassword}
                          keyboardType="default"
                          placeholderTextColor="#878787"
                          underlineColor="transparent"
                          activeUnderlineColor="transparent"
                          onChangeText={(text) => {
                            setCreatePassword(text);
                            setCodeError(false);
                          }}
                          theme={{ colors: { text: Colors.BLACK } }}
                        />
                      </View>

                      <View>
                        <TextInput
                          label={
                            <CoreText
                              textTitle={t("app.confirmPassword", "Bekräfta lösenord")}
                              style={[styles.input]}
                              fontType={MontserratRegular}
                              isInput
                            />
                          }
                          secureTextEntry={createPasswordVisible}
                          right={
                            <TextInput.Icon
                              name={createPasswordVisible ? "eye-off" : "eye"}
                              onPress={() =>
                                setCreatePasswordVisible(!createPasswordVisible)
                              }
                              theme={{ colors: { text: Colors.BLACK } }}
                            />
                          }
                          style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                          value={newPassword}
                          keyboardType="default"
                          placeholderTextColor="#878787"
                          underlineColor="transparent"
                          activeUnderlineColor="transparent"
                          onChangeText={(e) => {
                            setNewPassword(e);
                            setCodeError(false);
                          }}
                          theme={{ colors: { text: Colors.BLACK } }}
                        />
                        {codeError && (
                          <CoreText
                            textTitle={codeError && codeErrorMsg}
                            style={[codeError && styles.error]}
                            fontType={MontserratRegular}
                          />
                        )}
                      </View>
                    </View>
                    <View style={[styles.btnsection, { marginTop: 10 }]}>
                      <Button
                        btnTitle={t("app.submit", "Skicka in")}
                        isError={codeError}
                        disableButtondata={codeError}
                        disabled={codeError}
                        textstyle={[
                          !codeError
                            ? styles.btntextstyle
                            : styles.disableBtnStyle,
                          { lineHeight: 36, marginTop: 0 },
                        ]}
                        onPress={() => resetPassword()}
                      />
                    </View>
                  </>
                )}
              </Modal>
            </Portal>
            <View style={[styles.Landingcontainer]}>
              <CoreBanner
                imgUrl={orgDetail?.[0]?.reg_banner}
              />
              <View
                style={[
                  styles.alignItems,
                  styles.justifyContent,
                  {
                    marginHorizontal: 45,
                  },
                ]}
              >
                <View style={{ textAlign: "center" }}>
                  <CoreText
                    textTitle={orgDetail?.[0]?.reg_msg || t("app.login_button", "Logga in")}
                    style={[
                      styles.mainTitle,
                      {
                        paddingTop: 50,
                        color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                        textAlign: "center",
                      },
                    ]}
                    fontType={MontserratMedium}
                  />
                  <CoreText
                    textTitle={t("app.login_subtitle", "Fyll i dina uppgifter för att logga in")}
                    style={[
                      styles.subTitle,
                      { marginBottom: 30, marginTop: 15, textAlign: "center" },
                    ]}
                    fontType={MontserratExtraLight}
                  />
                </View>
              </View>
              <View style={{ marginHorizontal: 20 }}>
                <View style={{ marginBottom: 10 }}>
                  <TextInput
                    label={
                      <CoreText
                        textTitle={t("app.email", "Epost")}
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    autoComplete="off"
                    autoCompleteType="off"
                    style={[styles.inputFiledstyle]}
                    value={text || ""}
                    keyboardType="default"
                    testID="telenumber"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChangeText={(text) => onChange(text)}
                    theme={{ colors: { text: Colors.BLACK } }}
                  />
                  {isError ? (
                    <CoreText
                      textTitle={isError && erroMsg}
                      style={[isError && styles.error]}
                      fontType={MontserratRegular}
                    />
                  ) : (
                    <View />
                  )}
                </View>

                <View>
                  <TextInput
                    label={
                      <CoreText
                        textTitle={t("app.password", "Lösenord")}
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    autoComplete="off"
                    autoCompleteType="off"
                    style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                    secureTextEntry={passwordVisible}
                    right={
                      <TextInput.Icon
                        name={passwordVisible ? "eye-off" : "eye"}
                        onPress={() => setPasswordVisible(!passwordVisible)}
                        theme={{ colors: { text: Colors.BLACK } }}
                      />
                    }
                    value={password || ""}
                    maxLength={50}
                    keyboardType="default"
                    testID="telenumber"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChangeText={(e) => {
                      setPassword(e);
                      setNextBtnError("");
                    }}
                    onKeyPress={(e) => handleKeyPress(e.key)}
                    theme={{ colors: { text: Colors.BLACK } }}
                  />
                  {nextBtnError ? (
                    <View>
                      <CoreText
                        textTitle={nextBtnError}
                        style={[styles.error, { marginBottom: 10 }]}
                        fontType={MontserratRegular}
                      />
                    </View>
                  ) : (
                    <View />
                  )}
                </View>
                <View>
                  <TouchableOpacity onPress={showModal}>
                    <CoreText
                      textTitle={t("app.forgotPassword", "Glömt lösenordet?")}
                      style={[
                        styles.subTitle,
                        { marginBottom: 5, textAlign: "right" },
                      ]}
                      fontType={MontserratExtraLight}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </ScrollView>
          <View
            style={[
              styles.btnsection,
              styles.footerButtonSection,
              { marginBottom: 30 },
            ]}
          >
            <Button
              isLoad={isLoadCustomer}
              btnTitle={t("app.login_button", "Logga in")}
              isError={isError}
              issinup
              disableButtondata={isError || !text}
              disabled={isError || !text}
              textstyle={[
                !isError ? styles.btntextstyle : styles.disableBtnStyle,
                { lineHeight: 36, marginTop: 0 },
              ]}
              onPress={() => handleNext(text, password)}
            />
          </View>
          <CoreFooter />
        </KeyboardAvoidingView>
      </SafeAreaView>
    </Provider>
  );
};
export default Login;
