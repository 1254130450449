import {
  SET_PRODUCT,
  PRODUCT_DETAILS,
  CREATE_USER,
  ALL_CATEGORY,
  SET_USER_POOL_DATA,
  SET_ORGANIZATION_SETTINGS,
} from "../constants/actions-type";

export const setProduct = (products) => {
  return {
    type: SET_PRODUCT,
    payload: products,
  };
};

export const setProductDetails = (productDetail) => {
  return {
    type: PRODUCT_DETAILS,
    payload: productDetail,
  };
};

export const setCustomer = (customerDetail) => {
  return {
    type: CREATE_USER,
    payload: customerDetail,
  };
};

export const setCategory = (categories) => {
  return {
    type: ALL_CATEGORY,
    payload: categories,
  };
};

export const setUserPoolData = (userPool) => {
  return {
    type: SET_USER_POOL_DATA,
    payload: userPool,
  };
};
export const setOrganizationSettings = (data) => {
  return {
    type: SET_ORGANIZATION_SETTINGS,
    payload: data,
  };
};
