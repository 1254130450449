import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getLanguagesList } from "./actions";

const loadResources = async () => {
    try {
        const response = await getLanguagesList();
        return response.data;
    } catch (error) {
        console.error("Error loading resources: ", error);
        return {};
    }
};

const backendOptions = {
    loadPath: "{{lng}}",
    request: (options, url, payload, callback) => {
        loadResources()
            .then((response) => {
                const [lng] = url.split("|");
                const translations = response[lng] ? response[lng] : null;
                if (translations) {
                    callback(null, {
                        data: translations,
                        status: 200,
                    });
                } else {
                    callback(null, {
                        status: 404,
                    });
                }
            })
            .catch((e) => {
                console.error("Error loading resources: ", e);
                callback(null, {
                    status: 500,
                });
            });
    },
};

const initI18n = async () => {
    const storedLanguage = await AsyncStorage.getItem("i18nextLng");
    const defaultLanguage = storedLanguage || 'sw';
    console.log('defaultLanguage: ', defaultLanguage);

    await i18n
        .use(backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            debug: false,
            lng: defaultLanguage,
            fallbackLng: 'sw',
            supportedLngs: ['sw', 'en'],
            backend: backendOptions,
            interpolation: {
                escapeValue: false,
            },
        });

    // Update AsyncStorage with the default language if not set
    if (!storedLanguage) {
        await AsyncStorage.setItem("i18nextLng", defaultLanguage);
    }
};


initI18n();
// i18n
//     .use(backend)
//     .use(LanguageDetector)
//     .use(initReactI18next)
//     .init({
//         debug: false,
//         fallbackLng: 'sw',
//         supportedLngs: ['sw', 'en'],
//         backend: backendOptions,
//         interpolation: {
//             escapeValue: false,
//         },
//     });

export default i18n;
