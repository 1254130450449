import React, { useEffect, useState } from "react";
import { Image } from "react-native";
import styles from "../style/styles";
import CoreLoader from "../core/CoreLoader";

const imageSrc = require("../../assets/images/pre-loved-logo.png");
const seculateLogo = require("../../assets/images/serculateLogo.png");

const CoreImage = ({ imgUrl, isSignupPage, isCreateAccount, style }) => {
  const [isImageLoad, setIsImageLoad] = useState(true);
  const [defaualtImage, setDefaualtImage] = useState();

  useEffect(() => {
    if (!imgUrl) {
      setIsImageLoad(true);
      if (isImageLoad) {
        setDefaualtImage(imageSrc);
        setIsImageLoad(false);
      }
    } else {
      setIsImageLoad(false);
      setDefaualtImage(imageSrc);
    }
  }, []);

  return isImageLoad ? (
    <CoreLoader style={{ marginTop: 10, marginBottom: 20 }} />
  ) : (
    <Image
      style={[
        styles.tinyLogo,
        { marginVertical: isSignupPage ? 10 : 0 },
        { ...style },
      ]}
      source={
        imgUrl
          ? isCreateAccount
            ? seculateLogo
            : { uri: imgUrl }
          : defaualtImage
      }
    />
  );
};

export default CoreImage;
