import React from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import CoreText from "../core/CoreText";

const MontserratRegular = require("../../assets/Font/Montserrat-Regular.ttf");
const CoreFooter = () => {
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const email = orgDetail?.[0]?.email
  return (
    <>
      <View
        style={{
          position: "relative",
          marginTop: 40,
        }}
      >
        <View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#F5F5F5",
          }}
        >
          <CoreText
          textTitle={`Powered by Serculate ${email ? `, för frågor maila ${email}` : ''}`}
            style={{
              fontSize: 12,
              color: "#9D9D9D",
              textAlign: "center",
              paddingVertical: 15,
            }}
            fontType={MontserratRegular}
          />
        </View>
      </View>
    </>
  );
};

export default CoreFooter;
