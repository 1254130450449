import React from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  Image,
  TouchableOpacity,
} from "react-native";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

const ItemDetails = ({ navigation }) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.itemDetailsContainer}>
          <View style={styles.closeMenuSection}>
            <View style={styles.dotMenuIcon}>
              <MaterialCommunityIcons
                name="dots-horizontal"
                size={24}
                color={Colors.WHITE}
              />
            </View>
            <View style={styles.dotMenuIcon}>
              <AntDesign name="close" size={24} color={Colors.WHITE} />
            </View>
          </View>
          <View style={styles.itemDetail}>
            <TouchableOpacity
              onPress={() => navigation.navigate("DropOffBagItem")}
            >
              <Image
                style={styles.productImage}
                source={require("../assets/images/Hoodie.png")}
              />
            </TouchableOpacity>
            <View style={{ flexDirection: "column" }}>
              <View>
                <CoreText
                  style={styles.productDetails}
                  textTitle="Haglöfs"
                  fontType={MontserratBold}
                />
              </View>
              <View>
                <CoreText
                  FirstTitle="Herr "
                  lastTitle=" Jackor"
                  icon={
                    <AntDesign
                      name="right"
                      size={9}
                      color="rgba(15, 15, 15, 0.4)"
                    />
                  }
                  style={[styles.headerTitle, { color: "rgba(0, 0, 0, 0.48)" }]}
                  fontType={MontserratLight}
                />
              </View>
              <View style={styles.consideredByStore}>
                <CoreText
                  style={styles.storeText}
                  textTitle="Övervägs av butik"
                  fontType={MontserratBold}
                />
              </View>
            </View>
          </View>
          <View style={{ marginTop: 77 }}>
            <View style={styles.priceTagContainer}>
              <View style={styles.priceTagBody}>
                <CoreText textTitle="125,00 " fontType={MontserratRegular} />
                <CoreText
                  style={[styles.sektext, styles.dropsubtitle]}
                  textTitle="sek"
                  fontType={MontserratRegular}
                />
              </View>
            </View>
            <View style={styles.bagProductContainer}>
              <View>
                <View>
                  <CoreText
                    style={[styles.productDetails, { fontSize: 20 }]}
                    textTitle="Haglöfs"
                    fontType={MontserratBold}
                  />
                </View>
                <View>
                  <CoreText
                    FirstTitle="Herr "
                    lastTitle=" Jackor"
                    icon={
                      <AntDesign
                        name="right"
                        size={9}
                        color="rgba(15, 15, 15, 0.4)"
                      />
                    }
                    style={[
                      styles.headerTitle,
                      { color: "rgba(0, 0, 0, 0.48)" },
                    ]}
                    fontType={MontserratLight}
                  />
                </View>
              </View>
              <View style={[styles.consideredByStore, { marginTop: 0 }]}>
                <CoreText
                  style={styles.storeText}
                  textTitle="Övervägs av butiken"
                  fontType={MontserratBold}
                />
              </View>
            </View>
          </View>
          <View style={styles.itemDetailHR} />
        </View>
      </ScrollView>
      <View style={[styles.messageAlertSection]}>
        <View style={{ marginLeft: 34 }}>
          <MaterialCommunityIcons
            name="message-alert"
            size={20}
            color="rgba(50, 50, 50, 0.32)"
          />
        </View>
        <View style={styles.messageAlert}>
          <CoreText
            style={styles.alertText}
            textTitle="Så fort butiken har övervägt ditt plagg så får du veta om det har accepterats."
            fontType={MontserratLight}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};
export default ItemDetails;
