import React, { useState, useEffect } from "react";
import {
  View,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSelector, useDispatch } from "react-redux";
import { setProduct } from "../components/redux/actions/prodActions";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import { getOrganisationById, getProductDetails } from "../actions";
import { setAuthHeader } from "../axiosApi";
import CoreImage from "../components/core/CoreImage";
import { Status } from "../sharedLib/sourcingItemStatus.js";
import CoreLoader from "../components/core/CoreLoader";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

let orgName;
const DropOffItemAccept = ({ navigation, route }) => {
  const textValue = route.params.textValue || "";
  const prodDetails = useSelector((state) => state.prodReducer.productDetail);
  let getDataById = 0;
  const [userItems, setUserItems] = useState();
  const [isLoad, setIsLoad] = useState(true);
  const dispatch = useDispatch();
  const orgDetail = useSelector((state) => state.prodReducer.products);

  useEffect(async () => {
    orgName = await AsyncStorage.getItem("organisation")
    const _token = await AsyncStorage.getItem("token");
    setAuthHeader(_token);
    const { item } = route.params;
    getDataById = item;

    await getProductDetails(orgName).then(({ data }) => {
      setUserItems(data);
      getOrganisationById(data[0]?.organisation_id)
        .then((resp) => {
          dispatch(setProduct(resp?.data));
          setIsLoad(false);
        })
        .catch((error) => { });
    });
  }, []);

  const acceptedItems = userItems?.filter(
    (item) => item.status === Status.Accepted
  );

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={[styles.alignItems, styles.justifyContent]}>
            <CoreImage imgUrl={orgDetail?.[0]?.logoType} isSignupPage />
          </View>
          <View
            style={{
              paddingHorizontal: 15,
              paddingVertical: 15,
            }}
          >
            <View
              style={[
                styles.contentHeaderBody,
                { paddingBottom: 20, maxWidth: "100%" },
              ]}
            >
              <CoreText
                textTitle={
                  <>
                    Dina plagg är redo att lämnas in hos{" "}
                    <b style={{ color: Colors.BLACK }}>
                      {prodDetails?.organisation_id ||
                        prodDetails?.[0]?.organisation_id}
                    </b>
                  </>
                }
                style={styles.manageTitle}
                fontType={MontserratMedium}
              />
              <CoreText
                textTitle={
                  "Använd en påse och vik ner kläderna. Se till att följande plagg ar med. Fraktsedel skrivs ut hos Posten ombudet. Visa QR koden nedanför"
                }
                style={styles.manageTitle}
                fontType={MontserratMedium}
              />
            </View>
            {isLoad ? (
              <CoreLoader style={{ marginTop: 10, marginBottom: 20 }} />
            ) : (
              acceptedItems?.map((item, index) => (
                <View key={index}>
                  <View style={styles.productContainer}>
                    <View style={styles.productBody}>
                      <TouchableOpacity
                        onPress={() =>
                          navigation.navigate("DropOffItem", {
                            user: item.customer_id,
                            item: item.id,
                            organisationId: item.organisation_id,
                          })
                        }
                      >
                        <Image
                          style={styles.productImage}
                          source={{ uri: item.photo[0].url }}
                        />
                      </TouchableOpacity>
                      <View>
                        <CoreText
                          textTitle={item.brand}
                          style={styles.headerTitle}
                          fontType={MontserratRegular}
                        />
                        <CoreText
                          dropOffItem
                          textValue={textValue}
                          FirstTitle={textValue ? "" : item.gender}
                          lastTitle={item.category}
                          icon={
                            textValue ? (
                              ""
                            ) : (
                              <AntDesign
                                name="right"
                                size={9}
                                color="rgba(15, 15, 15, 0.4)"
                                onPress={() => navigation.goBack()}
                              />
                            )
                          }
                          style={[
                            styles.headerSubTitle,
                            { color: "rgba(0, 0, 0, 0.48)" },
                          ]}
                          fontType={MontserratLight}
                        />
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      styles.tophorizontalline,
                      { marginBottom: 15, marginTop: 20 },
                    ]}
                  />
                </View>
              ))
            )}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default DropOffItemAccept;
