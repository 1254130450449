import React, { useEffect, useState } from "react";
import { View, SafeAreaView, Dimensions, Text } from "react-native";
import CoreText from "../components/core/CoreText";
import styles from "../components/style/styles";
import { useSelector } from "react-redux";
import {
  Fontisto,
  Octicons,
  AntDesign,
  SimpleLineIcons,
  Entypo,
  Ionicons,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import {
  CognitoUser
} from "amazon-cognito-identity-js";
import Button from "../components/Button";
import { Grid, Col } from "react-native-easy-grid";
import { signOut } from "../components/core/CoreSignOut";
import { ScrollView } from "react-native-gesture-handler";
import { Card, TouchableRipple, Portal, Modal, TextInput, Provider } from "react-native-paper";
import CoreImage from "../components/core/CoreImage";
import Colors from "../constants/Colors";
import { useTranslation } from 'react-i18next';

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");

const FooterHumburderMenu = ({ navigation, route, setSideMenuVisible }) => {
  const { t } = useTranslation();
  const pickOrg = route?.params?.organisationId;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const switchItemData = orgDetail[0]?.theme?.setting[0]?.value === "true";
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const poolData = useSelector((state) => state.prodReducer.userPool);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const FaqList = orgSetting?.faq;
  const customOptionsData = orgSetting?.customOptions
    ? JSON.parse(orgSetting?.customOptions)
    : {};
  // forgetPassword
  const [visible, setVisible] = useState(false);
  const [createPassword, setCreatePassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMsg, setCodeErrorMsg] = useState("");
  const [createPasswordVisible, setCreatePasswordVisible] = useState(true);
  const [newPasswordVisible, setNewPasswordVisible] = useState(true);
  const { width } = Dimensions.get("window");

  const showModal = () => {
    setVisible(true);
    setCode("");
    setCodeError(false);
    setCodeErrorMsg("");
    setCreatePassword("");
    setNewPassword("");
  };
  const hideModal = () => {
    setVisible(false);
    setSideMenuVisible(false)
  };

  const userData = {
    Username: customers?.email,
    Pool: poolData,
  };

  let cognitoUser;
  if (userData?.Username && userData?.Pool) {
    cognitoUser = new CognitoUser(userData);
  } else {
    console.error('Username or Pool information is missing.');
  }

  const sendCode = () => {
    if (cognitoUser) {
      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          console.log("onSuccess:", data);
        },
        onFailure: (err) => {
          console.log("onFailure:", err?.message);
          setCodeError(true);
          setCodeErrorMsg(err?.message);
        },
        inputVerificationCode: (data) => {
          setCodeError(false);
          setCodeErrorMsg("");
        },
      });
    }
  };
  useEffect(() => {
    if (customers?.email && visible) {
      sendCode();
    }
  }, [customers?.email, visible])

  const resetPassword = () => {
    if (createPassword !== newPassword) {
      setCodeError(true);
      setCodeErrorMsg("Lösenordet är inte samma");
      return;
    }

    cognitoUser.confirmPassword(code, createPassword, {
      onSuccess: (data) => {
        setCodeError(false);
        setCodeErrorMsg("");
        hideModal();
      },
      onFailure: (err) => {
        if (code === "") {
          setCodeError(true);
          setCodeErrorMsg("Ange engångskoden");
        } else {
          setCodeError(true);
          setCodeErrorMsg(err?.message);
        }
      },
    });
  };


  const menuClick = (button) => {
    switch (button) {
      case "withdrawal":
        setSideMenuVisible(false);
        navigation.navigate("CommissionWithdraw", {
          organisationId: pickOrg,
        });
        break;

      case "contactus":
        setSideMenuVisible(false);
        navigation.navigate("ContactUs", {
          organisationId: pickOrg,
        });
        break;

      case "home":
        signOut(poolData);
        setSideMenuVisible(false);
        navigation.navigate("HomePage", {
          organisationId: pickOrg,
        });
        break;

      case "sourceItem":
        setSideMenuVisible(false);
        navigation.navigate("CreateSourceItem", {
          organisationId: pickOrg,
        });
        break;

      case "chat":
        setSideMenuVisible(false);
        navigation.navigate("ChatScreen", {
          organisationId: pickOrg,
        });
        break;

      case "mypage":
        setSideMenuVisible(false);
        if (orgDetail?.[0]?.cashierIntegration == "boxnet") {
          navigation.navigate("SoldClothBagCollectionIntegration", {
            organisationId: pickOrg,
          });
        } else {
          navigation.navigate("SoldClothBagCollection", {
            organisationId: pickOrg,
          });
        }

        break;

      case "faq":
        setSideMenuVisible(false);
        navigation.navigate("FaqPage", {
          organisationId: pickOrg,
        });
        break;

      case "resetPassword":
        showModal();
        break;

      case "signout":
        signOut(poolData);
        setSideMenuVisible(false);
        navigation.navigate("HomePage", {
          organisationId: pickOrg,
        });
        break;

      default:
        break;
    }
  };
  const addHeaderMenu = [];
  addHeaderMenu.push({
    title: t("app.home", "Hem"),
    iconImage: <AntDesign name="contacts" size={22} color="#fff" />,
    isClick: true,
    button: "home",
  });

  if (customers?.isLogin) {
    if (orgSetting?.self_register_item) {
      addHeaderMenu.push({
          title: t("app.create_item","Sälj varor med oss"),
          iconImage:<AntDesign name="pluscircleo" size={22} color="#fff" />,
          isClick: true,
          button: "sourceItem",
      });
    }
    addHeaderMenu.push(
      {
        title: t("app.main_page", "Min sida"),
        iconImage: <Fontisto name="nav-icon-list-a" size={18} color="#fff" />,
        isClick: true,
        button: "mypage",
      },
      {
        title: t("app.withdraw_money", "Ta ut pengar"),
        iconImage: <Octicons name="credit-card" size={22} color="#fff" />,
        isClick: true,
        button: "withdrawal",
      },
    );
    if (customOptionsData?.isShowChat) {
      addHeaderMenu.push({
        title: t("app.chat", "Chatt"),
        iconImage: (
          <Ionicons name="chatbubble-ellipses-outline" size={24} color="#fff" />
        ),
        isClick: true,
        button: "chat",
      });
    }
  }
  addHeaderMenu.push({
    title: t("app.contact_us", "Kontakta oss"),
    iconImage: <AntDesign name="contacts" size={22} color="#fff" />,
    isClick: true,
    button: "contactus",
  });
  if (FaqList) {
    addHeaderMenu.push({
      title: "FAQ",
      iconImage: <MaterialCommunityIcons name="frequently-asked-questions" size={22} color="#fff" />,
      isClick: true,
      button: "faq",
    });
  }

  if (customers?.isLogin) {
    addHeaderMenu.push({
      title: t("app.reset_password", "Återställ lösenord"),
      iconImage: <MaterialCommunityIcons name="lock-reset" size={24} color="#fff" />,
      isClick: true,
      button: "resetPassword",
    },
    {
      title: t("app.logOut", "Logga ut"),
      iconImage: <SimpleLineIcons name="logout" size={22} color="#fff" />,
      isClick: true,
      button: "signout",
    });
  }
  return (
    <Provider>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <ScrollView>
          <View style={{ paddingHorizontal: 20, paddingTop: 25 }}>
            <CoreImage imgUrl={orgDetail?.[0]?.logoType} />
            <View
              style={[
                styles.cardContainertitle,
                { marginHorizontal: 0, marginTop: 24 },
              ]}
            >
              <View style={styles.cardBody}>
                <Card
                  style={{
                    borderRadius: 12,
                    shadowColor: "transparent",
                    marginBottom: 16,
                  }}
                >
                  <TouchableRipple
                    style={[
                      styles.addHeaderButton,
                      switchItemData && styles.buttonNone,
                      {
                        backgroundColor:
                          orgDetail?.[0]?.primaryColor || Colors.BROWN,
                      },
                    ]}
                    onPress={() => {
                      setSideMenuVisible(false);
                      if (customers?.isLogin) {
                        navigation.navigate("LandingCritera", {
                          organisationId: pickOrg,
                        });
                      } else {
                        var org = pickOrg;
                        navigation.navigate("Signup", { org });
                      }
                    }}
                  >
                    <Grid>
                      <Col size={2}>
                        <Entypo name="plus" size={24} color="#fff" />
                      </Col>
                      <Col size={10}>
                        <CoreText
                          textTitle={t("app.sell_cloth", "Sälj kläder")}
                          style={[styles.headerTitle, { color: "white" }]}
                          fontType={MontserratBold}
                        />
                      </Col>
                    </Grid>
                  </TouchableRipple>
                </Card>
              </View>

              <View style={styles.cardBody}>
                {addHeaderMenu.map((item, index) => {
                  return (
                    item !== false && (
                      <Card
                        style={{
                          borderRadius: 12,
                          shadowColor: "transparent",
                          marginBottom: 16,
                        }}
                        key={index}
                      >
                        <TouchableRipple
                          style={[
                            styles.headerCardStyle,
                            {
                              backgroundColor:
                                orgDetail?.[0]?.primaryColor || Colors.BROWN,
                            },
                          ]}
                          rippleColor="rgba(0, 0, 0, .32)"
                          onPress={() => {
                            item.isClick && menuClick(item.button);
                          }}
                        >
                          <Grid>
                            <Col size={2}>{item.iconImage}</Col>
                            <Col size={10}>
                              <CoreText
                                textTitle={item.title}
                                style={[
                                  styles.headerTitle,
                                  { color: Colors.WHITE },
                                ]}
                                fontType={MontserratBold}
                              />
                            </Col>
                          </Grid>
                        </TouchableRipple>
                      </Card>
                    )
                  );
                })}
              </View>
            </View>
          </View>
          <Portal>
            <Modal
              visible={visible}
              onDismiss={hideModal}
              contentContainerStyle={{
                backgroundColor: "white",
                padding: 20,
                marginVertical: 0,
                marginHorizontal: 15,
                borderRadius: 10,
              }}
              style={{
                marginRight: 0,
                marginLeft: "auto",
                marginVertical: 0,
                width: width * 0.97,
              }}
            >
              <View>
                <Text style={{ fontWeight: 500 }}>Note: An email has been sent to you with a verification code to your account.</Text>
                <View style={{ marginTop: 10 }}>
                  <View style={{ marginBottom: 10 }}>
                    <TextInput
                      label={
                        <CoreText
                          textTitle={t("app.enterVerifyCode", "Fyll i återställningskod")}
                          style={[styles.input]}
                          fontType={MontserratRegular}
                          isInput
                        />
                      }
                      style={[styles.inputFiledstyle]}
                      value={code}
                      keyboardType="default"
                      placeholderTextColor="#878787"
                      underlineColor="transparent"
                      activeUnderlineColor="transparent"
                      onChangeText={(text) => {
                        setCode(text);
                        setCodeError(false);
                      }}
                      theme={{ colors: { text: Colors.BLACK } }}
                    />
                  </View>
                  <View style={{ marginBottom: 10 }}>
                    <TextInput
                      label={
                        <CoreText
                          textTitle={t("app.newPassword", "Nytt lösenord")}
                          style={[styles.input]}
                          fontType={MontserratRegular}
                          isInput
                        />
                      }
                      secureTextEntry={newPasswordVisible}
                      right={
                        <TextInput.Icon
                          name={newPasswordVisible ? "eye-off" : "eye"}
                          onPress={() =>
                            setNewPasswordVisible(!newPasswordVisible)
                          }
                          theme={{ colors: { text: Colors.BLACK } }}
                        />
                      }
                      style={[styles.inputFiledstyle]}
                      value={createPassword}
                      keyboardType="default"
                      placeholderTextColor="#878787"
                      underlineColor="transparent"
                      activeUnderlineColor="transparent"
                      onChangeText={(text) => {
                        setCreatePassword(text);
                        setCodeError(false);
                      }}
                      theme={{ colors: { text: Colors.BLACK } }}
                    />
                  </View>

                  <View>
                    <TextInput
                      label={
                        <CoreText
                          textTitle={t("app.confirmPassword", "Bekräfta lösenord")}
                          style={[styles.input]}
                          fontType={MontserratRegular}
                          isInput
                        />
                      }
                      secureTextEntry={createPasswordVisible}
                      right={
                        <TextInput.Icon
                          name={createPasswordVisible ? "eye-off" : "eye"}
                          onPress={() =>
                            setCreatePasswordVisible(!createPasswordVisible)
                          }
                          theme={{ colors: { text: Colors.BLACK } }}
                        />
                      }
                      style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                      value={newPassword}
                      keyboardType="default"
                      placeholderTextColor="#878787"
                      underlineColor="transparent"
                      activeUnderlineColor="transparent"
                      onChangeText={(e) => {
                        setNewPassword(e);
                        setCodeError(false);
                      }}
                      theme={{ colors: { text: Colors.BLACK } }}
                    />
                    {codeError && (
                      <CoreText
                        textTitle={codeError && codeErrorMsg}
                        style={[codeError && styles.error]}
                        fontType={MontserratRegular}
                      />
                    )}
                  </View>
                </View>
                <View style={[styles.btnsection, { marginTop: 10 }]}>
                  <Button
                    btnTitle={t("app.submit", "Skicka in")}
                    isError={codeError}
                    disableButtondata={codeError}
                    disabled={codeError}
                    textstyle={[
                      !codeError
                        ? styles.btntextstyle
                        : styles.disableBtnStyle,
                      { lineHeight: 36, marginTop: 0 },
                    ]}
                    onPress={() => resetPassword()}
                  />
                </View>
              </View>

            </Modal>
          </Portal>
        </ScrollView>
      </SafeAreaView>
    </Provider >
  );
};

export default FooterHumburderMenu;
