import React from "react";
import { View, Platform } from "react-native";
import MapView, { Marker, PROVIDER_GOOGLE } from "react-native-maps";
import { MaterialIcons } from "@expo/vector-icons";
import styles from "../../components/style/styles";
import { mapStyle } from "../../constants/mapStyle";
import Colors from "../../constants/Colors";

const MobileMap = ({ coordinate, orgDetail }) => {
  return (
    <View style={styles.mapLocation}>
      <MapView
        style={{ width: Platform.OS === "ios" ? 300 : 350, height: 170 }}
        loadingEnabled={true}
        region={{
          latitude: 59.340946,
          longitude: 18.08395,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }}
        provider={PROVIDER_GOOGLE}
        customMapStyle={mapStyle}
      >
        <Marker coordinate={coordinate}>
          <View>
            <MaterialIcons
              name="location-pin"
              size={30}
              color={
                orgDetail?.[0]?.primaryColor || Colors.BACKGROUND_COLOR_RUBY
              }
            />
          </View>
        </Marker>
      </MapView>
    </View>
  );
};

export default MobileMap;
