import React from "react";
import { View, Image, Platform, SafeAreaView, StatusBar } from "react-native";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const ZimplerStatus = ({ navigation, route }) => {
    const { t } = useTranslation();
    const pickUser = route.params.organisationId;
    const status = route.params.status;

    return (
        <SafeAreaView
            style={{
                flex: 1,
                paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
            }}
        >
            <Header navigation={navigation} route={route} isHeader />
            <View
                style={[
                    styles.Landingcontainer,
                    styles.justifyContent,
                    styles.alignItems,
                ]}
            >
                <View style={styles.blockdata}>
                    <View style={styles.successimageContainer}>
                        <Image
                            style={styles.imgbanner}
                            source={require(status === "fail"
                                ? "../assets/images/itemCancle.svg"
                                : "../assets/images/itemsuccess.png")}
                        />
                    </View>
                </View>

                <View style={styles.optionBody}>
                    <CoreText
                        textTitle={
                            status === "fail"
                                ? t("app.fail_message", "Betalning misslyckas")
                                : t("app.success_message", "Tack, beloppet betalas ut och kommer på ditt konto inom någon minut")
                        }
                        style={styles.titleHeader}
                        fontType={MontserratMedium}
                    />
                </View>
                <View style={[styles.buttonContainer, { marginBottom: 30 }]}>
                    <Button
                        btnTitle={t("app.continue", "Fortsätt")}
                        onPress={() =>
                            navigation.navigate("SoldClothBagCollection", {
                                organisationId: pickUser,
                            })
                        }
                        lineHeight={30}
                    />
                </View>
            </View>
            <CoreFooter />
        </SafeAreaView>
    );
};
export default ZimplerStatus;
