import React, { useEffect } from "react";
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import { Card } from "react-native-paper";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import Button from "../components/Button";
import {useSelector} from "react-redux";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const LandingCriteraAccept = ({ navigation, route }) => {
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const selfServiceFlow = orgDetail?.[0]?.selfServiceFlow;
  const pickOrg = route.params.organisationId;
  const approveGarmentType = route.params.approveData;
  const category = {};
  approveGarmentType.map((item) => {
    if (category[item.type]) {
      category[item.type] = [...category[item.type], item.category];
    } else {
      category[item.type] = [item.category];
    }
  });

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={styles.landingback}>
            <AntDesign
              name="left"
              size={24}
              color={Colors.LIGHTEST_GRAY}
              onPress={() => navigation.goBack()}
            />
          </View>
          <View
            style={[
              styles.contentHeader,
              { marginBottom: 0, marginHorizontal: 44 },
            ]}
          >
            <View style={styles.contentHeaderBody}>
              <View>
                <Ionicons
                  name="checkmark-circle-sharp"
                  style={[{ marginLeft: 5, marginTop: 40 }]}
                  size={33}
                  color={Colors.GREEN}
                />
              </View>
              <CoreText
                textTitle="Vi tar emot följande plagg"
                style={[styles.mainTitle, { marginTop: 40 }]}
                fontType={MontserratMedium}
              />
              <CoreText
                textTitle="För att vara säker på att vi kommer acceptera dina kläder behöver du följa dessa riktlinjer:"
                style={[styles.subTitle]}
                fontType={MontserratExtraLight}
              />
            </View>
          </View>

          <View style={[styles.cardContainer, { marginTop: 48 }]}>
            {Object.keys(category).map((key, index) => (
              <View style={styles.cardBody} key={index}>
                <Card style={styles.cardStyle}>
                  <CoreText
                    textTitle={key}
                    style={[
                      styles.headerTitle,
                      { textTransform: "capitalize" },
                    ]}
                    fontType={MontserratBold}
                  />
                  {category[key]?.map((item, index) => (
                    <Card.Title
                      key={index}
                      style={styles.cardTitleStyle}
                      leftStyle={styles.otherLeftStyle}
                      title={
                        <CoreText
                          textTitle={item}
                          style={[styles.subtitleStyle]}
                          fontType={MontserratRegular}
                        />
                      }
                      titleNumberOfLines={2}
                      left={() => (
                        <Ionicons
                          name="checkmark-circle-sharp"
                          style={[styles.icon, { marginTop: 2 }]}
                          size={18}
                          color={Colors.GREEN}
                        />
                      )}
                    />
                  ))}
                </Card>
              </View>
            ))}
          </View>
        </View>
      </ScrollView>
      <Button
        btnTitle="Fortsätt"
        onPress={() =>
          navigation.navigate(selfServiceFlow ? "AddItemProductType" : "Additem", {
            organisationId: pickOrg,
          })
        }
        style={[
          {
            marginHorizontal: 20,
            marginBottom: 21,
          },
        ]}
        lineHeight={32}
      />
    </SafeAreaView>
  );
};
export default LandingCriteraAccept;

const landingItem = [
  {
    cardTitle1: "Jackor",
  },
  {
    cardTitle1: "Västar",
  },
  {
    cardTitle1: "Byxor",
  },
  {
    cardTitle1: "Skjortor",
  },
  {
    cardTitle1: "Tröjor",
  },
  {
    cardTitle1: "Shorts",
  },
];
const landingItemdata = [
  {
    cardTitle1: "Haglöfs",
  },
  {
    cardTitle1: "Houdini",
  },
  {
    cardTitle1: "Endura",
  },
  {
    cardTitle1: "Fjällräven",
  },
];
