import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import CoreText from "./core/CoreText";
import styles from "./style/styles";
import Colors from "../constants/Colors";
import CoreLoader from "../components/core/CoreLoader";
import { useTranslation } from 'react-i18next';

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const MutlipleImageCamera = ({
  isImageLoad,
  orgDetail,
  RestartCamera,
  getImage,
  index,
  selectedIndex,
  item,
  subtitle,
}) => {
  const { t } = useTranslation();
  const isCurrent = index === selectedIndex;

  return (
    <View style={[styles.trademarkstyle, { marginTop: 40 }]}>
      <View
        style={{
          width: "50%",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <View
          style={[
            styles.MaterialIconsEdit,
            { alignSelf: "baseline", padding: 0 },
          ]}
        >
          <TouchableOpacity onPress={() => RestartCamera(index)}>
            <CoreText
              textTitle={t("app.add_image_button", "Lägg till bild")}
              style={[styles.imagebtn, { overflow: "hidden" }]}
              fontType={MontserratMedium}
              iconFront={<Ionicons name="add" size={12} color={Colors.BLACK} />}
            />
          </TouchableOpacity>
        </View>
        <View>
          <CoreText
            textTitle={subtitle}
            style={[{ color: Colors.GRAY, marginTop: 8 }]}
            fontType={MontserratMedium}
          />
        </View>
      </View>
      <View style={{ alignItems: "flex-end", flex: 1 }}>
        {isCurrent && isImageLoad ? (
          <CoreLoader
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              marginRight: 15,
            }}
          />
        ) : (
          <>
            <Image
              style={[styles.productImage, { borderRadius: 6, marginRight: 0 }]}
              source={{
                uri: getImage?.url || item.picture,
              }}
            />
          </>
        )}
      </View>
    </View>
  );
};

export default MutlipleImageCamera;
