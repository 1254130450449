import React from "react";
import {
  View,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import { useSelector } from "react-redux";
import { Card, Title } from "react-native-paper";
import { FontAwesome } from "@expo/vector-icons";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import Button from "../components/Button";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const NewCritera = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickOrg = route.params.organisationId;
  const orgDetail = useSelector((state) => state.prodReducer.products);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHeader />
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={[styles.contentHeader, { marginBottom: 40 }]}>
            <View style={styles.contentHeaderBody}>
              <CoreText
                textTitle={t("app.newCritera_title", "Hur funkar det?")}
                style={[
                  styles.mainTitle,
                  {
                    marginTop: 40,
                    color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                  },
                ]}
                fontType={MontserratMedium}
              />
            </View>
          </View>

          <View style={[styles.cardContainer, { marginTop: 0 }]}>
            <View style={styles.cardBody}>
              <Card style={styles.cardStyle}>
                <Title>
                  <CoreText
                    textTitle="Vad får jag?"
                    style={[styles.headerTitle]}
                    fontType={MontserratBold}
                  />
                </Title>
                {orgDetail?.[0]?.termsInstruction?.map((item, index) => (
                  <Card.Title
                    key={index}
                    subtitleNumberOfLines={2}
                    style={[
                      styles.cardTitleStyle,
                      {
                        alignItems: "flex-start",
                      },
                    ]}
                    leftStyle={styles.leftStyle}
                    title={
                      <CoreText
                        textTitle={item.subtitle}
                        style={[
                          styles.subtitleStyle,
                          {
                            display: "flex",
                            overflow: "visible",
                          },
                        ]}
                        fontType={MontserratRegular}
                      />
                    }
                    titleNumberOfLines={2}
                    left={() => (
                      <FontAwesome
                        name="circle"
                        style={[styles.icon]}
                        size={5}
                        color={Colors.BLACK}
                      />
                    )}
                  />
                ))}
              </Card>
            </View>
            <View
              style={[
                styles.cardBody,
                {
                  marginTop: 50,
                },
              ]}
            >
              <Card style={styles.cardStyle}>
                <Title>
                  <CoreText
                    textTitle={"Exempel på provision"}
                    style={[styles.headerTitle]}
                    fontType={MontserratBold}
                  />
                </Title>
                <Card.Title
                  subtitleNumberOfLines={2}
                  style={[
                    styles.cardTitleStyle,
                    {
                      alignItems: "baseline",
                    },
                  ]}
                  leftStyle={styles.leftStyle}
                  title={
                    <CoreText
                      textTitle={`Du får ${orgDetail?.[0]?.org_comission} kr om plagget säljs för 100 kr`}
                      style={[styles.subtitleStyle]}
                      fontType={MontserratRegular}
                    />
                  }
                  titleNumberOfLines={2}
                />
              </Card>
            </View>
          </View>
        </View>
      </ScrollView>
      <Button
        btnTitle={t("app.continue", "Fortsätt")}
        style={[
          styles.footerButtonSection,
          {
            marginHorizontal: 20,
            marginBottom: 30,
          },
        ]}
        onPress={() =>
          navigation.navigate("LandingCritera", {
            organisationId: pickOrg,
          })
        }
        lineHeight={30}
      />
      <CoreFooter />
    </SafeAreaView>
  );
};
export default NewCritera;
