import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  Image,
  Linking,
  Text
} from "react-native";
import { useSelector } from "react-redux";
import styles from "../components/style/styles";
import {
  addTransactionData,
  addCustomerAccountDetail,
  myDetails,
  adjustComissionData,
} from "../actions";
import CoreText from "../components/core/CoreText";
import { TextInput } from "react-native-paper";
import Button from "../components/Button";
import { ScrollView } from "react-native-gesture-handler";
import CoreFooter from "../components/core/CoreFooter";
import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";
import { useTranslation } from 'react-i18next';

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const CommissionWithdraw = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickOrg = route.params.organisationId;
  const [loadItem, setLoadItem] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [accountErrorMsg, setAccountErrorMsg] = useState("");
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [isLoadCustomer, setIsLoadCustomer] = useState(false);
  const [userData, setuserData] = useState("");
  const [amount, setAmount] = useState("");
  const [accountNumber, setAccountNumber] = useState();
  const [settlementAccountId, setSettlementAccountId] = useState();
  const [zimplerDisplay, setZimplerDisplay] = useState(false);
  const [manualDisplay, setManualDisplay] = useState(false);
  const [isGiftCard, setIsGiftCard] = useState(false);
  const [isPaymentFinShark, setIsPaymentFinShark] = useState(false);
  const [isPaidInStore, setIsPaidInStore] = useState(false);
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const orgSetting = useSelector((state) => state.prodReducer.orgSetting);
  const organisationId = orgDetail[0]?.urlPath;
  // const regex = /^[-+]?[0-9]+$/;
  const regex = /^[-+]?[0-9]+([.,][0-9]+)?$/;
  const reg = /^[A-Za-z0-9 ]+$/;

  useEffect(() => {
    setZimplerDisplay(false);
    setManualDisplay(false);
    setIsGiftCard(false);
    setIsPaymentFinShark(false);
    setIsPaidInStore(false)
  }, []);

  const disposableBalance =
    userData?.balance - userData?.unprocessed_payout_requests || 0;
  const withdrowAllMethod =
    orgSetting?.payoutMethod?.includes("zimpler") ||
    orgSetting?.payoutMethod?.includes("manual") ||
    orgSetting?.payoutMethod?.includes("giftCard") ||
    orgSetting?.payoutMethod?.includes("paidInStore") ||
    orgSetting?.payoutMethod?.includes("paymentfinshark");

  const zimplerMethod =
    (orgSetting?.isZimplerPublic &&
      orgSetting?.payoutMethod?.includes("zimpler")) ||
    (userData?.is_zimpler_enable &&
      orgSetting?.isZimplerEnable &&
      orgSetting?.payoutMethod?.includes("zimpler"));

  const manualMethod = orgSetting?.payoutMethod?.includes("manual")

  const giftCardMethod = orgSetting?.payoutMethod?.includes("giftCard");

  const paidInStoreMethod = orgSetting?.payoutMethod?.includes("paidInStore")

  const paymentFinsharkMethod =
    (orgSetting?.isFinsharkEnable || orgSetting?.isFinsharkPublic) &&
    orgSetting?.payoutMethod?.includes("paymentfinshark");

  useEffect(async () => {
    await myDetails(organisationId)
      .then((resp) => {
        setuserData(resp?.data[0]);
        setLoadItem(false);
        setAccountNumber(resp?.data[0]?.account_detail?.accountNo);
      })
      .catch((error) => { });
  }, []);

  const handleNextZimpler = async () => {
    setIsLoadCustomer(true);
    if (!reg.test(accountNumber)) {
      setAccountErrorMsg("inte lägga till specialtecken");
      setIsLoadCustomer(false);
    } else {
      const data = {
        organisationId: organisationId,
        email: userData?.email,
        accountDetail: {
          accountNo: accountNumber,
        },
      };
      setIsLoadCustomer(true);
      addCustomerAccountDetail(data);

      const normalizedAmount = amount.replace(",", ".");
      if (!regex.test(normalizedAmount)) {
        setErrorMsg("Ange ett belopp i siffror");
        setIsLoadCustomer(false);
      } else if (normalizedAmount && !/^\d+(\.\d{1,2})?$/.test(normalizedAmount)) {
        setErrorMsg('Beloppet måste ha upp till två decimaler.');
        setIsLoadCustomer(false);
      } else if (
        (!orgSetting?.isZimplerEnable ||
          (orgSetting?.isZimplerEnable && !userData?.is_zimpler_enable)) &&
        normalizedAmount > disposableBalance
      ) {
        setErrorMsg(
          `Beloppet kan inte vara högre än ditt tillgängliga saldo ${disposableBalance?.toFixed(
            2
          )}`
        );
        setIsLoadCustomer(false);
      } else if (
        (orgSetting?.isZimplerPublic ||
          (userData?.is_zimpler_enable && orgSetting?.isZimplerEnable)) &&
        normalizedAmount < 100
      ) {
        setErrorMsg("vänligen ange ditt belopp mer än 100");
        setIsLoadCustomer(false);
      } else if (
        orgSetting?.isZimplerPublic ||
        (userData?.is_zimpler_enable && orgSetting?.isZimplerEnable)
      ) {
        const data = {
          amount: parseFloat(normalizedAmount),
          isMobile: true,
        };
        adjustComissionData(data, organisationId).then((resp) => {
          if (resp?.data?.Success) {
            // Linking.openURL(resp?.data?.url);
            window.location.href = resp?.data?.url;
            // setTransactionSuccess(true);
          } else {
            setErrorMsg(resp?.data?.message);
          }

          setIsLoadCustomer(false);
        });
      }
    }
  };

  const handleNextManual = async () => {
    setIsLoadCustomer(true);
    if (!reg.test(accountNumber)) {
      setAccountErrorMsg("inte lägga till specialtecken");
      setIsLoadCustomer(false);
    } else {
      const data = {
        organisationId: organisationId,
        email: userData?.email,
        accountDetail: {
          accountNo: accountNumber,
        },
      };
      setIsLoadCustomer(true);
      addCustomerAccountDetail(data);

      const normalizedAmount = amount.replace(",", ".");
      if (!regex.test(normalizedAmount)) {
        setErrorMsg("Ange ett belopp i siffror");
        setIsLoadCustomer(false);
      } else if (normalizedAmount && !/^\d+(\.\d{1,2})?$/.test(normalizedAmount)) {
        setErrorMsg('Beloppet måste ha upp till två decimaler.');
        setIsLoadCustomer(false);
      } else {
        const data = {
          primaryKey: organisationId,
          organisation_id: organisationId,
          customer_id: userData?.id,
          amount: parseFloat(normalizedAmount),
          type: "withdrawal requested",
          description: "",
          account_detail: {
            accountNo: accountNumber,
          },
        };
        setIsLoadCustomer(true);
        addTransactionData(data, isGiftCard && "GiftCard").then((resp) => {
          setTransactionSuccess(true);
        });
      }
    }
  };

  const handlePaymentfinshark = async () => {
    setIsLoadCustomer(true);
    if (!reg.test(accountNumber)) {
      setAccountErrorMsg("inte lägga till specialtecken");
      setIsLoadCustomer(false);
    } else {
      const data = {
        organisationId: organisationId,
        email: userData?.email,
        accountDetail: {
          accountNo: accountNumber,
        },
      };
      setIsLoadCustomer(true);
      addCustomerAccountDetail(data);

      const normalizedAmount = amount.replace(",", ".");
      if (!regex.test(normalizedAmount)) {
        setErrorMsg("Ange ett belopp i siffror");
        setIsLoadCustomer(false);
      } else if (normalizedAmount && !/^\d+(\.\d{1,2})?$/.test(normalizedAmount)) {
        setErrorMsg('Beloppet måste ha upp till två decimaler.');
        setIsLoadCustomer(false);
      } 
      // else if (
      //   (!orgSetting?.isFinsharkEnable || orgSetting?.isFinsharkEnable) &&
      //   normalizedAmount > disposableBalance
      // ) {
      //   setErrorMsg(
      //     `Beloppet kan inte vara högre än ditt tillgängliga saldo ${disposableBalance?.toFixed(
      //       2
      //     )}`
      //   );
      //   setIsLoadCustomer(false);
       else if (
        (orgSetting?.isFinsharkPublic || orgSetting?.isFinsharkEnable) &&
        normalizedAmount < 1
        ) {
        setErrorMsg("vänligen ange ditt belopp mer än 0");
        setIsLoadCustomer(false);
      } else if (orgSetting?.isFinsharkPublic || orgSetting?.isFinsharkEnable) {
        const data = {
          amount: parseFloat(normalizedAmount),
          isMobile: true,
          type: "paymentfinshark",
          // settlementAccountId: settlementAccountId,
        };
        adjustComissionData(data, organisationId).then((resp) => {
          if (resp?.data?.url?.error) {
            setErrorMsg(resp?.data?.url?.error);
            setIsLoadCustomer(false);
          } else if (resp?.data?.message) {
            setErrorMsg(resp?.data?.message)
            setIsLoadCustomer(false);
          } else{
            window.location.href = resp?.data?.url;
          }
          setIsLoadCustomer(false);
        });
      }
    }
  };

  const accountNumberData = (
    <>
      <View style={styles.availableProduct}>
        <View style={{ marginTop: 10 }}>
          <CoreText
            style={styles.numberOfSold}
            textTitle={t("app.account_no_title", "Ange både clearing och kontonummer")}
            fontType={MontserratBold}
          />
        </View>
        <TextInput
          label={
            <CoreText
              textTitle={t("app.account_no_title", "Ange både clearing och kontonummer")}
              style={[styles.input]}
              fontType={MontserratRegular}
              isInput
            />
          }
          style={[styles.inputFiledstyle]}
          value={accountNumber}
          keyboardType="numeric"
          placeholderTextColor="#878787"
          underlineColor="transparent"
          activeUnderlineColor="transparent"
          onChangeText={(e) => {
            setAccountNumber(e);
            setAccountErrorMsg("");
          }}
        />
      </View>
      {accountErrorMsg && (
        <View style={{ marginHorizontal: 20 }}>
          <CoreText
            textTitle={accountErrorMsg}
            style={[styles.error]}
            fontType={MontserratRegular}
          />
        </View>
      )}
    </>
  );

  const accountPayment = (
    <>
      <View style={styles.availableProduct}>
        <View style={{ marginTop: 20 }}>
          <CoreText
            style={styles.numberOfSold}
            textTitle={t("app.withdraw_payment_title", "Skriv in beloppet du vill ta ut")}
            fontType={MontserratBold}
          />
        </View>
        <TextInput
          label={
            <CoreText
              textTitle={t("app.withdraw_payment_placeholder", "Ange belopp för att ta ut")}
              style={[styles.input]}
              fontType={MontserratRegular}
              isInput
            />
          }
          style={[styles.inputFiledstyle]}
          value={amount}
          keyboardType="numeric"
          placeholderTextColor="#878787"
          underlineColor="transparent"
          activeUnderlineColor="transparent"
          onChangeText={(e) => {
            setAmount(e);
            setErrorMsg("");
          }}
        />
      </View>
      {errorMsg && (
        <View style={{ marginHorizontal: 20 }}>
          <CoreText
            textTitle={errorMsg}
            style={[styles.error]}
            fontType={MontserratRegular}
          />
        </View>
      )}
      {orgDetail?.[0]?.costOfTransaction > 0 ? (
        <CoreText
          style={{
            padding: 15,
            color:
              orgDetail?.[0]?.primaryColor ||
              orgDetail[0]?.secondaryColor ||
              Colors.TEXT_GRAY,
            fontStyle: "italic",
          }}
          textTitle={`${t("app.withdraw_fee", "Vi tar en uttagsavgift på")} ${orgDetail?.[0]?.costOfTransaction
            } ${orgSetting?.currency ? orgSetting?.currency : "$"}`}
          fontType={MontserratBold}
        />
      ) : (
        ""
      )}
    </>
  );

  const handleSubmitZimpler = (
    <View style={[styles.btnsection, { marginTop: 30 }]}>
      <Button
        isLoad={isLoadCustomer}
        btnTitle={t("app.withdraw_button", "Ta ut pengar")}
        disableButtondata={
          accountErrorMsg ||
          errorMsg ||
          !amount ||
          (!orgSetting?.isZimplerEnable &&
            !userData?.is_zimpler_enable &&
            !orgSetting?.isZimplerPublic &&
            !accountNumber)
        }
        disabled={
          accountErrorMsg ||
          errorMsg ||
          !amount ||
          (!orgSetting?.isZimplerEnable &&
            !userData?.is_zimpler_enable &&
            !orgSetting?.isZimplerPublic &&
            !accountNumber)
        }
        textstyle={[
          !accountErrorMsg || !errorMsg
            ? styles.btntextstyle
            : styles.disableBtnStyle,
          { lineHeight: 36, marginTop: 0 },
        ]}
        onPress={() => handleNextZimpler()}
      />
    </View>
  );

  const handleSubmitManual = (
    <View style={[styles.btnsection, { marginTop: 30 }]}>
      <Button
        isLoad={isLoadCustomer}
        btnTitle={t("app.withdraw_button", "Ta ut pengar")}
        disableButtondata={
          accountErrorMsg || errorMsg || !amount || !accountNumber
        }
        disabled={accountErrorMsg || errorMsg || !amount || !accountNumber}
        textstyle={[
          !accountErrorMsg || !errorMsg
            ? styles.btntextstyle
            : styles.disableBtnStyle,
          { lineHeight: 36, marginTop: 0 },
        ]}
        onPress={() => handleNextManual()}
      />
    </View>
  );

  const handleSubmitPaymentFinshark = (
    <View style={[styles.btnsection, { marginTop: 30 }]}>
      <Button
        isLoad={isLoadCustomer}
        btnTitle={t("app.withdraw_button", "Ta ut pengar")}
        disableButtondata={
          accountErrorMsg ||
          errorMsg ||
          !amount
        }
        disabled={
          accountErrorMsg ||
          errorMsg ||
          !amount
        }
        textstyle={[
          !accountErrorMsg || !errorMsg
            ? styles.btntextstyle
            : styles.disableBtnStyle,
          { lineHeight: 36, marginTop: 0 },
        ]}
        onPress={() => handlePaymentfinshark()}
      />
    </View>
  );

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {loadItem ? (
        <CoreLoader
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <>
          <Header navigation={navigation} route={route} isHomePage isHeader />
          {!transactionSuccess ? (
            <ScrollView>
              <View>
                <View style={styles.coreBagHeader}>
                  <CoreBagCollectionHeader isHeaderImage />
                </View>
              </View>
              <View style={{ marginHorizontal: 20, marginVertical: 10 }}>
                <View style={{ marginTop: 10 }}>
                  <Text style={[{ fontFamily: "Montserrat" }, styles.numberOfSold]}>
                    {t("app.commisionWithdraw_Desc", "Här kan du begära ut dina pengar. När vi har mottagit din förfrågan så kommer vi godkänna det och därefter betala ut i enlighet med avtalet.")}
                  </Text>
                </View>
                {userData?.unprocessed_payout_requests > 0 && (
                  <View style={{ marginTop: 10 }}>
                    <CoreText
                      style={styles.numberOfSold}
                      textTitle={
                        <>
                          Du har begärt ett uttag på{""}
                          <span style={{ fontWeight: 600, color: "#000" }}>
                            {" "}
                            {userData?.unprocessed_payout_requests}
                          </span>{" "}
                          som fortfarande bearbetas. Din tillgängliga balans är
                          <span style={{ fontWeight: 600, color: "#000" }}>
                            {" "}
                            {disposableBalance?.toFixed(2)}.
                          </span>
                        </>
                      }
                      fontType={MontserratBold}
                    />
                  </View>
                )}
              </View>
              <View>
                <>
                  {!zimplerDisplay &&
                    !manualDisplay &&
                    !isGiftCard &&
                    !isPaymentFinShark &&
                    !isPaidInStore &&
                    zimplerMethod && (
                      <View style={[styles.btnsection, { marginTop: 15 }]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={t("app.withdraw_button_1", "Direkt utbetalning till bank")}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setZimplerDisplay(true);
                            setManualDisplay(false);
                            setIsGiftCard(false);
                            setIsPaymentFinShark(false);
                            setIsPaidInStore(false)
                          }}
                        />
                      </View>
                    )}
                  {!manualDisplay &&
                    !zimplerDisplay &&
                    !isGiftCard &&
                    !isPaymentFinShark &&
                    !isPaidInStore &&
                    manualMethod && (
                      <View style={[styles.btnsection]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={t("app.withdraw_button_2", "Överföring till bank")}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setManualDisplay(true);
                            setZimplerDisplay(false);
                            setIsGiftCard(false);
                            setIsPaymentFinShark(false);
                            setIsPaidInStore(false)
                          }}
                        />
                      </View>
                    )}

                  {!manualDisplay &&
                    !zimplerDisplay &&
                    !isGiftCard &&
                    !isPaymentFinShark &&
                    !isPaidInStore &&
                    giftCardMethod && (
                      <View style={[styles.btnsection]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={t("app.withdraw_button_3", "Present kort")}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setIsGiftCard(true);
                            setManualDisplay(false);
                            setZimplerDisplay(false);
                            setIsPaymentFinShark(false);
                            setIsPaidInStore(false)
                          }}
                        />
                      </View>
                    )}

                  {!manualDisplay &&
                    !zimplerDisplay &&
                    !isGiftCard &&
                    !isPaymentFinShark &&
                    !isPaidInStore &&
                    paidInStoreMethod && (
                      <View style={[styles.btnsection]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={t("app.withdraw_button_5", "Betalas i butik")}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setIsPaidInStore(true)
                            setIsGiftCard(false);
                            setManualDisplay(false);
                            setZimplerDisplay(false);
                            setIsPaymentFinShark(false);
                          }}
                        />
                      </View>
                    )}
                  {!manualDisplay &&
                    !zimplerDisplay &&
                    !isGiftCard &&
                    !isPaymentFinShark &&
                    !isPaidInStore &&
                    paymentFinsharkMethod && (
                      <View style={[styles.btnsection]}>
                        <Button
                          isLoad={isLoadCustomer}
                          btnTitle={t("app.withdraw_button_4", "Direkt betalning av FinShark")}
                          textstyle={[
                            styles.btntextstyle,
                            { lineHeight: 36, marginTop: 0 },
                          ]}
                          onPress={() => {
                            setIsPaymentFinShark(true);
                            setIsGiftCard(false);
                            setManualDisplay(false);
                            setZimplerDisplay(false);
                            setIsPaidInStore(false)
                          }}
                        />
                      </View>
                    )}
                </>
              </View>
              <View>
                {withdrowAllMethod ? (
                  <>
                    {zimplerDisplay && zimplerMethod ? (
                      <>
                        {accountPayment}
                        {handleSubmitZimpler}
                      </>
                    ) : (manualDisplay || isGiftCard || isPaidInStore) && (manualMethod || giftCardMethod || paidInStoreMethod) ? (
                      <>
                        {accountNumberData}
                        {accountPayment}
                        {handleSubmitManual}
                      </>
                    ) : isPaymentFinShark && paymentFinsharkMethod ? (
                      <>
                        {/* <View style={styles.availableProduct}>
                          <View style={{ marginTop: 10 }}>
                            <CoreText
                              style={styles.numberOfSold}
                              textTitle={t("app.account_no_title","Ange både clearing och kontonummer")}
                              fontType={MontserratBold}
                            />
                          </View>
                          <TextInput
                            label={
                              <CoreText
                                textTitle={t("app.account_no_title","Ange både clearing och kontonummer")}
                                style={[styles.input]}
                                fontType={MontserratRegular}
                                isInput
                              />
                            }
                            style={[styles.inputFiledstyle]}
                            value={settlementAccountId}
                            keyboardType="numeric"
                            placeholderTextColor="#878787"
                            underlineColor="transparent"
                            activeUnderlineColor="transparent"
                            onChangeText={(e) => {
                              setSettlementAccountId(e);
                              setErrorMsg("");
                            }}
                          />
                          </View> */}
                        {accountPayment}
                        {handleSubmitPaymentFinshark}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    {accountNumberData}
                    {accountPayment}
                    {handleSubmitManual}
                  </>
                )}
              </View>
            </ScrollView>
          ) : (
            <ScrollView>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "72vh",
                }}
              >
                <View style={styles.blockdata}>
                  <View style={styles.successimageContainer}>
                    <Image
                      style={styles.imgbanner}
                      source={require("../assets/images/itemsuccess.png")}
                    />
                  </View>
                </View>
                <CoreText
                  textTitle="Tack, beloppet betalas ut så fort vi har godkänt förfrågan"
                  style={styles.titleHeader}
                  fontType={MontserratMedium}
                />
              </View>
              <View>
                <Button
                  btnTitle={t("app.continue", "Fortsätt")}
                  onPress={() =>
                    navigation.navigate("SoldClothBagCollection", {
                      organisationId: pickOrg,
                    })
                  }
                  style={[
                    {
                      marginHorizontal: 20,
                    },
                  ]}
                  lineHeight={30}
                />
              </View>
            </ScrollView>
          )}
          <CoreFooter />
        </>
      )
      }
    </SafeAreaView >
  );
};
export default CommissionWithdraw;
