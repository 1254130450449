import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ProgressBar } from "react-native-paper";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { Slider } from "react-native-elements";
import EditableData from "../components/EditableData";
import CoreText from "../components/core/CoreText";
import styles from "../components/style/styles";
import Colors from "../constants/Colors";
import { getProductDetails, postProductDetails } from "../actions";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

let orgName;
const RetailItemProductType = ({ navigation }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const getData = async () => {
      orgName = await AsyncStorage.getItem("organisation")
      try {
        const { data } = await getProductDetails(orgName);
      } catch (e) {
        e;
      }
    };
    getData();
  }, []);

  //   const handleClick = () => {
  //     const getData = async () => {
  //       try {
  //         const [postData] = [
  //           {
  //             status: "In preview",
  //             approved: true,
  //             type: "Jacket",
  //             brand: "abc",
  //             gender: "female",
  //             category: "Houdini",
  //             priceinterval: progress,
  //             photo: capturedImage || image,
  //             created: new Date(),
  //             resellerid: "reseller",
  //           },
  //         ];
  //         const { data } = await postProductDetails(postData);
  //         console.log(data);
  //       } catch (e) {
  //         console.log({ e });
  //       }
  //     };
  //     getData();
  //   };

  return (
    <View
      style={{
        flex: 1,
        color: "#0F0F0F",
      }}
    >
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <ScrollView>
          <View style={styles.containerHeader}>
            <AntDesign
              name="left"
              size={24}
              color="rgba(15, 15, 15, 0.24)"
              onPress={() => navigation.goBack()}
            />
            <View style={styles.FlexContainer}>
              <ProgressBar
                progress={0.8}
                color={Colors.BLACK}
                style={styles.progress}
              />
            </View>
            <CoreText
              textTitle="4 av 5"
              style={{
                fontSize: 14,
                fontWeight: "600",
                color: "rgba(0, 0, 0, 0.3)",
                lineHeight: 24,
              }}
              fontType={MontserratRegular}
            />
          </View>
          <View style={styles.horizontalRole} />
          <View style={styles.trademarkstyle}>
            <View
              style={{
                alignItems: "center",
                flex: 1,
                flexDirection: "row",
                // marginHorizontal: 20,
              }}
            >
              <Image
                style={{
                  height: 198,
                  width: 130,
                  marginTop: 24,
                  resizeMode: "center",
                  marginRight: 12,
                  borderRadius: 12,
                  overflow: "hidden",
                }}
                source={require("../assets/images/newJacket.png")}
              />
              <Image
                style={{
                  height: 198,
                  width: 130,
                  marginTop: 24,
                  resizeMode: "center",
                  borderRadius: 12,
                  marginRight: 12,
                  overflow: "hidden",
                }}
                source={require("../assets/images/newJacket.png")}
              />
              <Image
                style={{
                  height: 198,
                  width: 130,
                  marginTop: 24,
                  resizeMode: "center",
                  borderRadius: 12,
                  overflow: "hidden",
                  opacity: 0.48,
                }}
                source={require("../assets/images/newJacket.png")}
              />
            </View>
            <View style={{ alignSelf: "center" }}>
              <MaterialIcons
                name="mode-edit"
                size={15}
                color="rgba(15, 15, 15, 0.72)"
                style={styles.MaterialIconsEdit}
              />
            </View>
          </View>

          <EditableData
            title={"Plaggtyp"}
            inputvalue={"Herr > Jackor "}
            testID="one_input"
          />
          <View style={styles.horizontalRole} />
          <EditableData
            title={"Varumärke"}
            inputvalue={"Haglöfs"}
            testID="two_input"
            isSecond
          />
          <View style={styles.horizontalRole} />
          <View style={styles.slider}>
            <View>
              <CoreText
                textTitle="Sätt pris "
                style={styles.textStyle}
                fontType={MontserratBold}
                iconBack={
                  <AntDesign
                    name="questioncircle"
                    size={13}
                    color="rgba(15, 15, 15, 0.32)"
                  />
                }
              />
            </View>
            <View style={styles.pricestyle}>
              <CoreText
                textTitle={progress}
                style={{
                  color: "#000",
                  fontSize: 24,
                  lineHeight: 32,
                }}
                fontType={MontserratBold}
              />
              <CoreText
                textTitle="SEK"
                style={{
                  color: "rgba(0, 0, 0, 0.4)",
                }}
                fontType={MontserratBold}
              />
            </View>
            <Slider
              style={{ borderRadius: 10 }}
              maximumValue={1300}
              minimumValue={200}
              step={100}
              trackStyle={{ height: 8, borderRadius: 8 }}
              minimumTrackTintColor={Colors.BLACK}
              thumbTintColor={Colors.BLACK}
              maximumTrackTintColor="rgba(0, 0, 0, 0.08)"
              thumbStyle={styles.thumbStyle}
              onValueChange={(value) => setProgress(value)}
            />
            <CoreText
              textTitle={
                "Haglöfs jackor säljer snabbt 6 dagar i snitt för ett pris på 1200 SEK"
              }
              style={{
                color: "#000",
                fontSize: 14,
                lineHeight: 32,
                fontWeight: "400",
                marginBottom: 51,
              }}
              fontType={MontserratBold}
            />
          </View>
        </ScrollView>
        <View
          style={{
            marginHorizontal: 20,
            marginBottom: 20,
          }}
        >
          <TouchableOpacity
            style={[styles.button, { backgroundColor: Colors.BLACK }]}
            onPress={() => {
              handleClick();
              navigation.navigate("AddItemSuccess");
            }}
          >
            <CoreText
              textTitle={"Acceptera plagg"}
              style={[styles.btntext, { color: Colors.WHITE, lineHeight: 30 }]}
              fontType={MontserratBold}
            />
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </View>
  );
};
export default RetailItemProductType;
