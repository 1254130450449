import "react-native-gesture-handler";
import config from "./config";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { hotjar } from 'react-hotjar';
import { useLoadedAssets } from "./hooks/useLoadedAssets";
import Navigation from "./navigation";
import { useColorScheme } from "react-native";
import TagManager from 'react-gtm-module'
import "./i18n"

export default function App() {
  const isLoadingComplete = useLoadedAssets();
  const colorScheme = useColorScheme();
  if (config.APP_ENV === "production") {
    hotjar.initialize(config.HOTJAR_ID, config.HOTJAR_SV);
    TagManager.initialize({
      gtmId: config.GTM_ID
    });
  }
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <Navigation colorScheme={colorScheme} />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
}
