import React, { useEffect, useState } from "react";
import { Image, Platform } from "react-native";
import styles from "../style/styles";
import CoreLoader from "../core/CoreLoader";

const bannerSrc = require("../../assets/images/banner.png");

const CoreBanner = ({ imgUrl, isCoreImage }) => {

  const [isBannerLoad, setIsBannerLoad] = useState(true);
  const [banner, setBanner] = useState();

  useEffect(() => {
    if (!imgUrl) {
      setIsBannerLoad(true);
      if (isBannerLoad) {
        setBanner(bannerSrc);
        setIsBannerLoad(false);
      }
    } else {
      setIsBannerLoad(false);
      setBanner(bannerSrc);
    }
  }, []);

  return isBannerLoad ? (
    <CoreLoader style={{ marginTop: 40 }} />
  ) : (
    <Image
      style={[
        styles.banner,
        {
          alignSelf: imgUrl || Platform.OS === "web" ? "auto" : "center",
        },
        isCoreImage && styles.coreBanner,
      ]}
      source={imgUrl ? { uri: imgUrl } : banner}
    />
  );
};

export default CoreBanner;
