import React from "react";
import { View, Platform, SafeAreaView, StatusBar, Image } from "react-native";
import { Card } from "react-native-paper";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const retailInfo = [
  {
    retailTitle: "Checka ut plagg",
    retailSubTitle: "Tryck här för innan du säljer ett vintage plagg",
  },
  {
    retailTitle: "Checka in plagg via leverans",
    retailSubTitle: "Har ni tagit imot en leverans av plagg",
  },
  {
    retailTitle: "Checka in plagg i butik",
    retailSubTitle: "Om en kund kommer in i butik och vill lämna in",
  },
  {
    retailTitle: "Uppdatera vad vi söker",
    retailSubTitle: "Meddela era resellers om vad ni söker just nu",
  },
];

const RetailerHome = ({ navigation }) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.bagCollectionContainer}>
          <View style={[styles.bagItemContainer, { alignItems: "baseline" }]}>
            <View style={{ height: 90 }}>
              <CoreText
                style={styles.clothItemText}
                textTitle="Petra Andersson"
                fontType={MontserratBold}
              />
            </View>
            <View style={styles.clothItemImage}>
              <Image
                style={styles.clothImageStyle}
                source={require("../assets/images/person_outline.png")}
              />
            </View>
          </View>
        </View>
        <View
          style={{ marginTop: 36, marginRight: 37, marginLeft: 20, width: 333 }}
        >
          <CoreText
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: Colors.DARK_TEXT_GRAY,
              lineHeight: 24,
            }}
            textTitle="Insikter idag"
            fontType={MontserratBold}
          />
          <CoreText
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: Colors.DARK_TEXT_GRAY,
              lineHeight: 24,
            }}
            textTitle="Det finns 53 plagg i butiken"
            fontType={MontserratBold}
          />
          <CoreText
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: Colors.DARK_TEXT_GRAY,
              lineHeight: 24,
            }}
            textTitle="I snitt har det kommit in 7 plagg per dag"
            fontType={MontserratBold}
          />
          <CoreText
            style={{
              fontSize: 14,
              fontWeight: "700",
              color: Colors.DARK_TEXT_GRAY,
              lineHeight: 24,
            }}
            textTitle="Ett plagg säljs i snitt på 4 dagar, bästa kategorin är jackor"
            fontType={MontserratBold}
          />
        </View>
        <View style={{ marginHorizontal: 20 }}>
          <View
            style={{
              width: Platform.OS === "ios" ? 300 : 326,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CoreText
              textTitle="Välj vad du ska göra"
              style={[styles.mainTitle, { paddingTop: 32 }]}
              fontType={MontserratMedium}
            />
          </View>
        </View>
        <View style={{ marginTop: 24, marginHorizontal: 20 }}>
          {retailInfo?.map((item, index) => (
            <View style={{ marginBottom: 14 }} key={index}>
              <Card
                style={{
                  backgroundColor: Colors.LIGHT_TEXT_GRAY,
                  borderRadius: 12,
                  shadowColor: "transparent",
                  marginVertical: 4,
                }}
                onPress={() => navigation.navigate("QRcodeScanner")}
              >
                <Card.Content style={styles.cardContent}>
                  <CoreText
                    textTitle={item.retailTitle}
                    fontType={MontserratBold}
                    style={[
                      styles.cardTitle,
                      styles.carddropitem,
                      { color: "rgba(15, 15, 15, 0.8)" },
                    ]}
                  />
                  <CoreText
                    textTitle={item.retailSubTitle}
                    fontType={MontserratRegular}
                    style={[
                      styles.CardParagraph,
                      { color: Colors.DARK_TEXT_GRAY },
                    ]}
                  />
                </Card.Content>
              </Card>
            </View>
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default RetailerHome;
