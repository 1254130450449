export const countryCurrency = [
  {
    label: "Afghanistan (AFN ؋)",
    value: "Afghanistan (AFN ؋)",
  },
  {
    label: "Albania (ALL Lek)",
    value: "Albania (ALL Lek)",
  },
  {
    label: "Argentina (ARS $)",
    value: "Argentina (ARS $)",
  },
  {
    label: "Aruba (AWG ƒ)",
    value: "Aruba (AWG ƒ)",
  },
  {
    label: "Australia (AUD $)",
    value: "Australia (AUD $)",
  },
  {
    label: "Azerbaijan (AZN  )",
    value: "Azerbaijan (AZN  )",
  },
  {
    label: "Bahamas (BSD $)",
    value: "Bahamas (BSD $)",
  },
  {
    label: "Barbados (BBD $)",
    value: "Barbados (BBD $)",
  },
  {
    label: "Belarus (BYN Br)",
    value: "Belarus (BYN Br)",
  },
  {
    label: "Belize (BZD BZ$)",
    value: "Belize (BZD BZ$)",
  },
  {
    label: "Bermuda (BMD $)",
    value: "Bermuda (BMD $)",
  },
  {
    label: "Bolivia (BOB $b)",
    value: "Bolivia (BOB $b)",
  },
  {
    label: "Bosnia Convertible Mark (BAM KM)",
    value: "Bosnia Convertible Mark (BAM KM)",
  },
  {
    label: "Botswana (BWP P)",
    value: "Botswana (BWP P)",
  },
  {
    label: "Bulgaria (BGN лв)",
    value: "Bulgaria (BGN лв)",
  },
  {
    label: "Brazil (BRL R$)",
    value: "Brazil (BRL R$)",
  },
  {
    label: "Brunei Darussalam (BND $)",
    value: "Brunei Darussalam (BND $)",
  },
  {
    label: "Cambodia (KHR ៛)",
    value: "Cambodia (KHR ៛)",
  },
  {
    label: "Canada (CAD $)",
    value: "Canada (CAD $)",
  },
  {
    label: "Cayman Islands (KYD $)",
    value: "Cayman Islands (KYD $)",
  },
  {
    label: "Chile (CLP $)",
    value: "Chile (CLP $)",
  },
  {
    label: "China (CNY ¥)",
    value: "China (CNY ¥)",
  },
  {
    label: "Colombia (COP $)",
    value: "Colombia (COP $)",
  },
  {
    label: "Costa Rica (CRC ₡)",
    value: "Costa Rica (CRC ₡)",
  },
  {
    label: "Croatia (HRK kn)",
    value: "Croatia (HRK kn)",
  },
  {
    label: "Cuba (CUP ₱)",
    value: "Cuba (CUP ₱)",
  },
  {
    label: "Czech Republic (CZK Kč)",
    value: "Czech Republic (CZK Kč)",
  },
  {
    label: "Denmark (DKK kr)",
    value: "Denmark (DKK kr)",
  },
  {
    label: "Dominican Republic (DOP RD$)",
    value: "Dominican Republic (DOP RD$)",
  },
  {
    label: "East Caribbean (XCD $)",
    value: "East Caribbean (XCD $)",
  },
  {
    label: "Egypt (EGP £)",
    value: "Egypt (EGP £)",
  },
  {
    label: "El Salvador (SVC $)",
    value: "El Salvador (SVC $)",
  },
  {
    label: "Euro Member Countries (EUR €)",
    value: "Euro Member Countries (EUR €)",
  },
  {
    label: "Falkland Islands (FKP £)",
    value: "Falkland Islands (FKP £)",
  },
  {
    label: "Fiji (FJD $)",
    value: "Fiji (FJD $)",
  },
  {
    label: "Ghana (GHS ¢)",
    value: "Ghana (GHS ¢)",
  },
  {
    label: "Gibraltar (GIP £)",
    value: "Gibraltar (GIP £)",
  },
  {
    label: "Guatemala (GTQ Q)",
    value: "Guatemala (GTQ Q)",
  },
  {
    label: "Guernsey (GGP £)",
    value: "Guernsey (GGP £)",
  },
  {
    label: "Guyana (GYD $)",
    value: "Guyana (GYD $)",
  },
  {
    label: "Honduras (HNL L)",
    value: "Honduras (HNL L)",
  },
  {
    label: "Hong Kong (HKD $)",
    value: "Hong Kong (HKD $)",
  },
  {
    label: "Hungary (HUF Ft)",
    value: "Hungary (HUF Ft)",
  },
  {
    label: "Iceland (ISK kr)",
    value: "Iceland (ISK kr)",
  },
  {
    label: "India (INR ₹)",
    value: "India (INR ₹)",
  },
  {
    label: "Indonesia (IDR Rp)",
    value: "Indonesia (IDR Rp)",
  },
  {
    label: "Iran (IRR ﷼)",
    value: "Iran (IRR ﷼)",
  },
  {
    label: "Isle of Man (IMP £)",
    value: "Isle of Man (IMP £)",
  },
  {
    label: "Israel (ILS ₪)",
    value: "Israel (ILS ₪)",
  },
  {
    label: "Jamaica (JMD J$)",
    value: "Jamaica (JMD J$)",
  },
  {
    label: "Japan (JPY ¥)",
    value: "Japan (JPY ¥)",
  },
  {
    label: "Jersey (JEP £)",
    value: "Jersey (JEP £)",
  },
  {
    label: "Kazakhstan (KZT лв)",
    value: "Kazakhstan (KZT лв)",
  },
  {
    label: "Korea (North) (KPW ₩)",
    value: "Korea (North) (KPW ₩)",
  },
  {
    label: "Korea (South) (KRW ₩)",
    value: "Korea (South) (KRW ₩)",
  },
  {
    label: "Kyrgyzstan (KGS лв)",
    value: "Kyrgyzstan (KGS лв)",
  },
  {
    label: "Laos (LAK ₭)",
    value: "Laos (LAK ₭)",
  },
  {
    label: "Lebanon (LBP £)",
    value: "Lebanon (LBP £)",
  },
  {
    label: "Liberia (LRD $)",
    value: "Liberia (LRD $)",
  },
  {
    label: "Macedonia (MKD ден)",
    value: "Macedonia (MKD ден)",
  },
  {
    label: "Malaysia (MYR RM)",
    value: "Malaysia (MYR RM)",
  },
  {
    label: "Mauritius (MUR Rs)",
    value: "Mauritius (MUR Rs)",
  },
  {
    label: "Mexico (MXN $)",
    value: "Mexico (MXN $)",
  },
  {
    label: "Mongolia (MNT ₮)",
    value: "Mongolia (MNT ₮)",
  },
  {
    label: "Mozambique (MZN MT)",
    value: "Mozambique (MZN MT)",
  },
  {
    label: "Namibia (NAD $)",
    value: "Namibia (NAD $)",
  },
  {
    label: "Nepal (NPR Rs)",
    value: "Nepal (NPR Rs)",
  },
  {
    label: "Netherlands Antilles (ANG ƒ)",
    value: "Netherlands Antilles (ANG ƒ)",
  },
  {
    label: "New Zealand (NZD $)",
    value: "New Zealand (NZD $)",
  },
  {
    label: "Nicaragua (NIO C$)",
    value: "Nicaragua (NIO C$)",
  },
  {
    label: "Nigeria (NGN ₦)",
    value: "Nigeria (NGN ₦)",
  },
  {
    label: "Norway (NOK kr)",
    value: "Norway (NOK kr)",
  },
  {
    label: "Oman (OMR ﷼)",
    value: "Oman (OMR ﷼)",
  },
  {
    label: "Pakistan (PKR Rs)",
    value: "Pakistan (PKR Rs)",
  },
  {
    label: "Panama (PAB B/.)",
    value: "Panama (PAB B/.)",
  },
  {
    label: "Paraguay (PYG Gs)",
    value: "Paraguay (PYG Gs)",
  },
  {
    label: "Peru (PEN S/.)",
    value: "Peru (PEN S/.)",
  },
  {
    label: "Philippines (PHP ₱)",
    value: "Philippines (PHP ₱)",
  },
  {
    label: "Poland (PLN zł)",
    value: "Poland (PLN zł)",
  },
  {
    label: "Qatar (QAR ﷼)",
    value: "Qatar (QAR ﷼)",
  },
  {
    label: "Romania (RON lei)",
    value: "Romania (RON lei)",
  },
  {
    label: "Russia (RUB ₽)",
    value: "Russia (RUB ₽)",
  },
  {
    label: "Saint Helena (SHP £)",
    value: "Saint Helena (SHP £)",
  },
  {
    label: "Saudi Arabia (SAR ﷼)",
    value: "Saudi Arabia (SAR ﷼)",
  },
  {
    label: "Serbia (RSD Дин.)",
    value: "Serbia (RSD Дин.)",
  },
  {
    label: "Seychelles (SCR Rs)",
    value: "Seychelles (SCR Rs)",
  },
  {
    label: "Singapore (SGD $)",
    value: "Singapore (SGD $)",
  },
  {
    label: "Solomon Islands (SBD $)",
    value: "Solomon Islands (SBD $)",
  },
  {
    label: "Somalia (SOS S)",
    value: "Somalia (SOS S)",
  },
  {
    label: "South Africa (ZAR R)",
    value: "South Africa (ZAR R)",
  },
  {
    label: "Sri Lanka (LKR Rs)",
    value: "Sri Lanka (LKR Rs)",
  },
  {
    label: "Sweden (SEK kr)",
    value: "Sweden (SEK kr)",
  },
  {
    label: "Switzerland (CHF CHF)",
    value: "Switzerland (CHF CHF)",
  },
  {
    label: "Suriname (SRD $)",
    value: "Suriname (SRD $)",
  },
  {
    label: "Syria (SYP £)",
    value: "Syria (SYP £)",
  },
  {
    label: "Taiwan (TWD NT$)",
    value: "Taiwan (TWD NT$)",
  },
  {
    label: "Thailand (THB ฿))",
    value: "Thailand (THB ฿))",
  },
  {
    label: "Trinidad and Tobago (TTD TT$)",
    value: "Trinidad and Tobago (TTD TT$)",
  },
  {
    label: "Turkey (TRY ₺)",
    value: "Turkey (TRY ₺)",
  },
  {
    label: "Tuvalu (TVD $)",
    value: "Tuvalu (TVD $)",
  },
  {
    label: "Ukraine (UAH ₴)",
    value: "Ukraine (UAH ₴)",
  },
  {
    label: "United Kingdom (GBP £)",
    value: "United Kingdom (GBP £)",
  },
  {
    label: "United States (USD $)",
    value: "United States (USD $)",
  },
  {
    label: "Uruguay (UYU $U)",
    value: "Uruguay (UYU $U)",
  },
  {
    label: "Uzbekistan (UZS лв)",
    value: "Uzbekistan (UZS лв)",
  },
  {
    label: "Venezuela (VEF Bs)",
    value: "Venezuela (VEF Bs)",
  },
  {
    label: "Viet Nam (VND ₫)",
    value: "Viet Nam (VND ₫)",
  },
  {
    label: "Yemen (YER ﷼)",
    value: "Yemen (YER ﷼)",
  },
  {
    label: "Zimbabwe (ZWD Z$)",
    value: "Zimbabwe (ZWD Z$)",
  },
];
