export const Languages = [
  {
    label: "Arabic",
    value: "Arabic",
  },
  {
    label: "Chinese",
    value: "Chinese",
  },
  {
    label: "Czech",
    value: "Czech",
  },
  {
    label: "Danish",
    value: "Danish",
  },
  {
    label: "Dutch",
    value: "Dutch",
  },
  {
    label: "Finnish",
    value: "Finnish",
  },
  {
    label: "French",
    value: "French",
  },
  {
    label: "Galician",
    value: "Galician",
  },
  {
    label: "German",
    value: "German",
  },
  {
    label: "Hindi",
    value: "Hindi",
  },

  {
    label: "English",
    value: "English",
  },
  {
    label: "Italian",
    value: "Italian",
  },
  {
    label: "Japanese",
    value: "Japanese",
  },
  {
    label: "Korean",
    value: "Korean",
  },
  {
    label: "Persian",
    value: "Persian",
  },
  {
    label: "Polish",
    value: "Polish",
  },
  {
    label: "Portuguese",
    value: "Portuguese",
  },
  {
    label: "Russian",
    value: "Russian",
  },
  {
    label: "Slovak",
    value: "Slovak",
  },
  {
    label: "Spanish",
    value: "Spanish",
  },

  {
    label: "Swedish",
    value: "Swedish",
  },
  {
    label: "Thai",
    value: "Thai",
  },
  {
    label: "Turkish",
    value: "Turkish",
  },
  {
    label: "Ukrainian",
    value: "Ukrainian",
  },
  {
    label: "Vietnamese",
    value: "Vietnamese",
  },
];
