import React from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  Image,
  TouchableOpacity,
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import SwiperRetailItem from "../components/swiper/SwiperRetailItem";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

const RetailItemDetails = ({ navigation }) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View>
          <View
            style={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={[
                styles.dotMenuIcon,
                { position: "absolute", zIndex: 1, right: 20, top: 20 },
              ]}
            >
              <AntDesign name="close" size={24} color={Colors.WHITE} />
            </View>
            <View style={{ width: 359, borderRadius: 24, overflow: "hidden" }}>
              <SwiperRetailItem />
            </View>
            {/* <View style={{ width: 359, borderRadius: 24, overflow: "hidden" }}>
              <Image
                source={require("../assets/images/newJacket.png")}
                style={{
                  width: null,
                  // height: 608,
                  height: 545,
                  flex: 1,
                  resizeMode: "cover",
                }}
              />
            </View> */}
          </View>
          <View style={{ marginTop: 0, marginHorizontal: 20 }}>
            <View style={[styles.priceTagContainer, { marginTop: -32 }]}>
              <View style={styles.priceTagBody}>
                <CoreText textTitle="125,00 " fontType={MontserratRegular} />
                <CoreText
                  style={[styles.sektext, styles.dropsubtitle]}
                  textTitle="sek"
                  fontType={MontserratRegular}
                />
              </View>
            </View>
            <View style={styles.bagProductContainer}>
              <View>
                <View>
                  <CoreText
                    style={[styles.productDetails, { fontSize: 20 }]}
                    textTitle="Haglöfs"
                    fontType={MontserratBold}
                  />
                </View>
                <View>
                  <CoreText
                    FirstTitle="Herr "
                    lastTitle=" Jackor"
                    icon={
                      <AntDesign
                        name="right"
                        size={9}
                        color="rgba(15, 15, 15, 0.4)"
                      />
                    }
                    style={[
                      styles.headerTitle,
                      { color: "rgba(0, 0, 0, 0.48)" },
                    ]}
                    fontType={MontserratLight}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <TouchableOpacity
        style={[styles.darkButton, { marginHorizontal: 20, marginBottom: 21 }]}
        onPress={() => navigation.navigate("RetailItemProductType")}
      >
        <CoreText
          textTitle="Markera som såld"
          style={[styles.btntextstyle, { lineHeight: 30 }]}
          fontType={MontserratBold}
        />
      </TouchableOpacity>
    </SafeAreaView>
  );
};
export default RetailItemDetails;
