const config = {
  SITE_TITLE: process.env.REACT_APP_SITE_TITLE,
  API_URL: process.env.REACT_APP_API_URL,
  APP_ENV:  process.env.REACT_APP_ENV,
  HOTJAR_ID: 3087901,
  HOTJAR_SV: 6,
  GTM_ID: 'G-KK13S58DW6'
};

const currentEnvironment = process.env.REACT_APP_ENV;

console.log("Current environment is: ", currentEnvironment, process.env.REACT_APP_ENV, config.APP_ENV);

export default config;
