import React, { useState } from "react";
import { Col, Grid } from "react-native-easy-grid";
import { View, Platform, SafeAreaView, StatusBar } from "react-native";
import { Card } from "react-native-paper";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import { useSelector } from "react-redux";
import MobileMap from "../components/googlemap/MobileMap";
import WebMap from "../components/googlemap/WebMap";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const dropOffItem = [
  {
    itemTitle: "Beställ upphämtningspåse",
    itemSubtitle: "1-2 arbetsdagar",
  },
  {
    itemTitle: "Lämna in hos Instabox",
    itemSubtitle: "(500m) Hemköp Nära",
  },
];

const DropOffItemLocation = ({ navigation, route }) => {
  // const cartImage = route.params.cartImage;
  const [coordinate, setCoordinate] = useState({
    latitude: 59.340946,
    longitude: 18.08395,
  });
  const orgDetail = useSelector((state) => state.prodReducer.products);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={[styles.Landingcontainer, styles.dropitems]}>
          <View style={{ alignItems: "center" }}>
            <CoreText
              style={styles.maptitle}
              textTitle="Lämna in dina plagg"
              fontType={MontserratBold}
            />
          </View>
          {Platform.OS === "web" ? (
            <WebMap />
          ) : (
            <MobileMap coordinate={coordinate} orgDetail={orgDetail} />
          )}

          <View style={[styles.produdtDetailsContainer, { marginBottom: 0 }]}>
            <View>
              <CoreText
                style={styles.productDetails}
                textTitle="Naturkompaniet"
                fontType={MontserratBold}
              />
              <CoreText
                style={styles.productAddress}
                textTitle="Sibyllegatan 47, 114 42 Stockholm"
                fontType={MontserratRegular}
              />
            </View>
          </View>
          <View style={styles.maphorizontalline} />
          <View>
            <CoreText
              style={styles.productDetails}
              textTitle="Öppettider"
              fontType={MontserratBold}
            />
            <Grid>
              <Col>
                <CoreText
                  style={styles.weekDays}
                  textTitle="Måndag-Fredag"
                  fontType={MontserratRegular}
                />
                <CoreText
                  style={styles.weekDays}
                  textTitle="Lördag-Söndag"
                  fontType={MontserratRegular}
                />
              </Col>
              <Col>
                <CoreText
                  style={styles.timing}
                  textTitle="10:00 - 19:00"
                  fontType={MontserratRegular}
                />
                <CoreText
                  style={styles.timing}
                  textTitle="11:00 - 17:00"
                  fontType={MontserratRegular}
                />
              </Col>
            </Grid>
          </View>
          <View style={[styles.DropcardContainer, { marginTop: 70 }]}>
            <Card style={styles.DropMaincard}>
              <Card.Content style={styles.cardContent}>
                <CoreText
                  textTitle="Lämna in i butik"
                  fontType={MontserratBold}
                  style={[styles.cardTitle, styles.carddropitem]}
                />
                <CoreText
                  textTitle="Visa QR-kod"
                  fontType={MontserratRegular}
                  style={styles.CardParagraph}
                />
              </Card.Content>
            </Card>
          </View>
          {dropOffItem.map((item, index) => (
            <View style={styles.otherCardContainer} key={index}>
              <Card
                style={styles.DropMaincard}
                // onPress={() => navigation.navigate("ItemSoid", { cartImage })}
              >
                <Card.Content style={styles.cardContent}>
                  <CoreText
                    textTitle={item.itemTitle}
                    fontType={MontserratBold}
                    style={[styles.cardTitle, styles.carddropitem]}
                  />
                  <CoreText
                    textTitle={item.itemSubtitle}
                    fontType={MontserratRegular}
                    style={styles.CardParagraph}
                  />
                </Card.Content>
              </Card>
            </View>
          ))}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
export default DropOffItemLocation;
