import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import { TextInput } from "react-native-paper";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { Col, Grid } from "react-native-easy-grid";
import styles from "../components/style/styles.js";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const AddAddress = ({ navigation }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postalAddress, setPostalAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleChange = (fieldType, value) => {
    if (fieldType === "firstName") {
      setFirstName(value);
    } else if (fieldType === "lastName") {
      setLastName(value);
    } else if (fieldType === "postalAddress") {
      if (value === "") {
        setDisabled(true);
        setPostalAddress(value);
      } else {
        setPostalAddress(value);
        setDisabled(false);
      }
    } else if (fieldType === "city") {
      setCity(value);
    } else if (fieldType === "postcode") {
      setPostcode(value);
    }
  };

  useEffect(() => {
    if (postalAddress === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <ScrollView>
          <View style={styles.Landingcontainer}>
            <View style={styles.landingback}>
              <AntDesign
                name="left"
                size={24}
                color={Colors.LIGHTEST_GRAY}
                onPress={() => navigation.goBack()}
              />
            </View>
            <View style={[styles.contentHeader, { marginBottom: 123 }]}>
              <View style={styles.contentHeaderBody}>
                <View>
                  <MaterialIcons
                    name="house-siding"
                    size={33}
                    style={[{ marginLeft: 5, marginTop: 40 }]}
                    size={33}
                    color={Colors.LIGHTEST_GRAY}
                    onPress={() => navigation.goBack()}
                  />
                </View>
                <CoreText
                  textTitle="Fyll i din address så vi kan skicka dig en påse"
                  style={[styles.mainTitle, { marginTop: 40 }]}
                  fontType={MontserratMedium}
                />
              </View>
            </View>

            <View style={styles.bagFormContainer}>
              <Grid>
                <Col>
                  <View style={styles.firstNameInput}>
                    <TextInput
                      label={
                        <CoreText
                          textTitle="Förnamn"
                          style={styles.input}
                          fontType={MontserratMedium}
                        />
                      }
                      style={styles.addAddressInput}
                      value={firstName}
                      onChangeText={(text) => handleChange("firstName", text)}
                      placeholderTextColor="rgba(18, 18, 18, 0.75)"
                      underlineColor="transparent"
                      activeUnderlineColor="transparent"
                    />
                  </View>
                </Col>
                <Col>
                  <View style={{}}>
                    <TextInput
                      label={
                        <CoreText
                          textTitle="Efternamn"
                          style={styles.input}
                          fontType={MontserratMedium}
                        />
                      }
                      style={styles.addAddressInput}
                      value={lastName}
                      onChangeText={(text) => handleChange("lastName", text)}
                      placeholderTextColor="rgba(18, 18, 18, 0.75)"
                      underlineColor="transparent"
                      activeUnderlineColor="transparent"
                    />
                  </View>
                </Col>
              </Grid>
              <View style={{ marginTop: 16 }}>
                <TextInput
                  // render={() => (
                  //   <CoreText
                  //     textTitle="Lägg till C/O"
                  //     style={[styles.input, { color: Colors.BLACK }]}
                  //     fontType={MontserratMedium}
                  //   />
                  // )}
                  right={
                    <TextInput.Affix
                      text={
                        <CoreText
                          textTitle="Lägg till C/O"
                          style={[styles.input, { color: Colors.BLACK }]}
                          fontType={MontserratMedium}
                        />
                      }
                    />
                  }
                  label={
                    <CoreText
                      textTitle="Postadress"
                      style={styles.input}
                      fontType={MontserratMedium}
                    />
                  }
                  style={styles.addAddressInput}
                  value={postalAddress}
                  onChangeText={(text) => handleChange("postalAddress", text)}
                  placeholderTextColor="rgba(18, 18, 18, 0.75)"
                  underlineColor="transparent"
                  activeUnderlineColor="transparent"
                />
              </View>
              <View style={styles.cityInputContainer}>
                <Grid>
                  <Col style={{ width: 187 }}>
                    <View style={styles.cityInput}>
                      <TextInput
                        label={
                          <CoreText
                            textTitle="Stad"
                            style={styles.input}
                            fontType={MontserratMedium}
                          />
                        }
                        style={styles.addAddressInput}
                        value={city}
                        onChangeText={(text) => handleChange("city", text)}
                        placeholderTextColor="rgba(18, 18, 18, 0.75)"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                      />
                    </View>
                  </Col>
                  <Col>
                    <View style={styles.postcodeInput}>
                      <TextInput
                        label={
                          <CoreText
                            textTitle="Postkod"
                            style={styles.input}
                            fontType={MontserratMedium}
                          />
                        }
                        style={styles.addAddressInput}
                        value={postcode}
                        onChangeText={(text) => handleChange("postcode", text)}
                        placeholderTextColor="rgba(18, 18, 18, 0.75)"
                        underlineColor="transparent"
                        activeUnderlineColor="transparent"
                        maxLength={6}
                        keyboardType="numeric"
                      />
                    </View>
                  </Col>
                </Grid>
              </View>
            </View>
          </View>
        </ScrollView>

        <TouchableOpacity
          style={[
            disabled ? styles.darkDisableButton : styles.darkButton,
            { marginHorizontal: 20, marginBottom: 21 },
          ]}
          onPress={() => navigation.navigate("BookedBag")}
          disabled={disabled}
        >
          <CoreText
            textTitle="Beställ påse"
            style={[styles.btntextstyle, { lineHeight: 30 }]}
            fontType={MontserratBold}
          />
        </TouchableOpacity>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default AddAddress;
