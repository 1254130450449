import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  View,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
} from "react-native";
import { useSelector, useDispatch } from "react-redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import {
  setProduct,
  setCustomer,
} from "../components/redux/actions/prodActions";
import {
  getSignUp,
  loginByEmailIntegration,
  loginByEmailIntegrationStep2,
} from "../actions";
import styles from "../components/style/styles.js";
import { TextInput } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import CoreImage from "../components/core/CoreImage";
import CoreBanner from "../components/core/CoreBanner";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import { setAuthHeader } from "../axiosApi";
import UserPool from "../components/aws-exports";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratExtraLight = require("../assets/Font/Montserrat-Thin.ttf");

const LoginExternal = ({ navigation, route }) => {
  const pickUser = route.params.organisationId;
  const [email, setEmail] = useState("");
  const [oneTimeCode, setOneTimeCode] = useState("");
  const [oneTimeCodeRequired, setOneTimeCodeRequired] = useState("");
  const [passwordRequired, setPasswordRequired] = useState("");
  const [userList, setUserList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadCustomer, setIsLoadCustomer] = useState(false);
  const [erroMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [nextBtnError, setNextBtnError] = useState("");
  const re = /^[0-9\b]+$/;
  // support '+' oprator
  const reg =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // not support '+' oprator
  // const reg =
  // /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|(^\+[0-9]{12})+$/;
  const poolData = useSelector((state) => state.prodReducer.userPool);
  const userPool = poolData;
  const orgDetail = useSelector((state) => state.prodReducer.products);
  const dispatch = useDispatch();

  const onChange = (value) => {
    if (!value) {
      setIsError(true);
      setEmail("");
    } else {
      setIsError(false);
      if (re.test(value)) {
        const numberFormat = value.replace(
          /^(.{3})(.{3})(.{2})(.{2})$/,
          "$1 $2 $3 $4"
        );
        setEmail(numberFormat);
      } else {
        setEmail(value);
      }
    }
  };

  useEffect(() => {
    if (email === "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, []);

  useEffect(async () => {
    if (orgDetail) {
      setIsLoad(false);
    } else {
      setIsLoad(true);
    }
  }, []);

  const handleNext = async (username, password) => {
    setIsLoadCustomer(true);
    if (username === "") {
      setIsError(true);
      setErrorMsg("Ange epost");
      setIsLoadCustomer(false);
    } else if (!reg.test(username.split(" ").join(""))) {
      setIsError(true);
      setErrorMsg("Ange ett giltigt epost");
      setIsLoadCustomer(false);
    } else {
      setErrorMsg("");

      // if (route.params.organisationId) {
      if (!passwordRequired && !oneTimeCodeRequired) {
        console.log("Execute integration");
        var res = await loginByEmailIntegration(username.toLowerCase());
        setIsLoadCustomer(false);
        var data = res.data;
        if (data.success == false) {
          setIsError(true);
          setErrorMsg("Felaktigt lösenord eller email");
        } else {
          // Set the customer object
          //var customer = data.body;
          console.log(data.body);
          if (data.oneTimeCode) {
            setOneTimeCodeRequired(true);
            setPasswordRequired(true);
          }
          if (data.password) {
            setPasswordRequired(true);
          }
          //let loginStatus = customer;
          //let loginStatusItem = customer;
          //loginStatusItem.isLogin = true;
          //dispatch(setCustomer(customer));
          //navigation.navigate("SoldClothBagCollectionIntegration");
        }
      }
      if (passwordRequired && oneTimeCodeRequired) {
        var res = await loginByEmailIntegrationStep2(
          username.toLowerCase(),
          oneTimeCode,
          password
        );
        setIsLoadCustomer(false);
        var data = res.data;
        if (data.Success == false) {
          setIsError(true);
          setErrorMsg(
            "Något gick fel, kontakta oss i butiken eller prova igen senare"
          );
        } else {
          performLogin();
        }
      }
      if (passwordRequired) {
        performLogin();
      }
    }
  };

  const performLogin = function () {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };

    const congnitoUser = new CognitoUser(userData);

    congnitoUser.authenticateUser(authDetails, {
      onSuccess(result = CognitoUserSession) {
        if (result) {
          AsyncStorage.setItem("user", JSON.stringify({ ...result }));
          AsyncStorage.setItem("token", result?.idToken?.jwtToken);
          const getUserInfo = result?.idToken?.payload;
          setAuthHeader(result?.idToken?.jwtToken);
          const cutomerData = {
            email: getUserInfo?.email,
            phoneNumber: getUserInfo?.phone_number,
            id: getUserInfo?.sub,
            isLogin: true,
          };
          dispatch(setCustomer(cutomerData));
          AsyncStorage.setItem("customer", JSON.stringify(cutomerData));
          setTimeout(() => {
            navigation.navigate("SoldClothBagCollectionIntegration");
          }, 1000);
        }
      },
      onFailure(err) {
        console.log(err);
        const { message } = err;
        setNextBtnError(message);
        setIsLoadCustomer(false);
      },
    });
  };

  return isLoad ? (
    <CoreLoader
      style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    />
  ) : (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        enabled
      >
        <ScrollView>
          <View style={[styles.Landingcontainer]}>
            <Header navigation={navigation} route={route} isHomePage isHeader />
            <CoreBanner
              imgUrl={orgDetail?.[0]?.reg_banner}
            />
            <View
              style={[
                styles.alignItems,
                styles.justifyContent,
                {
                  // paddingBottom: 63,
                },
              ]}
            >
              <View style={[styles.contentHeaderBody]}>
                <CoreText
                  textTitle={orgDetail?.[0]?.reg_msg || "Logga in"}
                  style={[styles.mainTitle, { paddingTop: 32 }]}
                  fontType={MontserratMedium}
                />
                <CoreText
                  textTitle="Fyll i dina uppgifter för att logga in"
                  style={[styles.subTitle, { marginBottom: 5 }]}
                  fontType={MontserratExtraLight}
                />
              </View>
            </View>
            <View style={{ marginHorizontal: 20 }}>
              <View style={{ marginBottom: 10 }}>
                {!oneTimeCodeRequired ? (
                  <TextInput
                    label={
                      <CoreText
                        textTitle="Epost"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                      />
                    }
                    style={[styles.inputFiledstyle]}
                    value={email}
                    keyboardType="email-address"
                    testID="telenumber"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChangeText={(email) => onChange(email)}
                  />
                ) : (
                  <View
                    style={[
                      styles.alignItems,
                      styles.justifyContent,
                      {
                        // paddingBottom: 63,
                      },
                    ]}
                  >
                    <CoreText
                      textTitle="En engångskod har skickats till din epost, använd koden för att skapa ditt lösenord"
                      style={[styles.subTitle]}
                      fontType={MontserratRegular}
                    />
                  </View>
                )}

                {isError ? (
                  <CoreText
                    textTitle={erroMsg}
                    style={styles.error}
                    fontType={MontserratRegular}
                  />
                ) : (
                  <View />
                )}
              </View>
              {oneTimeCodeRequired ? (
                <View>
                  <TextInput
                    label={
                      <CoreText
                        textTitle="Engångskod"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                      />
                    }
                    style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                    value={oneTimeCode}
                    maxLength={50}
                    keyboardType="numeric"
                    testID="telenumber"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChangeText={(e) => setOneTimeCode(e)}
                  />
                </View>
              ) : (
                <View />
              )}

              <View>
                {passwordRequired ? (
                  <TextInput
                    secureTextEntry={true}
                    label={
                      <CoreText
                        textTitle="Lösenord"
                        style={[styles.input]}
                        fontType={MontserratRegular}
                      />
                    }
                    style={[styles.inputFiledstyle, { marginBottom: 10 }]}
                    value={password}
                    maxLength={50}
                    keyboardType="default"
                    testID="telenumber"
                    placeholderTextColor="#878787"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    onChangeText={(e) => setPassword(e)}
                  />
                ) : (
                  <View />
                )}
                {nextBtnError ? (
                  <View>
                    <CoreText
                      textTitle={nextBtnError}
                      style={[styles.error, { marginBottom: 10 }]}
                      fontType={MontserratRegular}
                    />
                  </View>
                ) : (
                  <View />
                )}
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={[styles.btnsection]}>
          <Button
            isLoad={isLoadCustomer}
            btnTitle="Logga in"
            isError={isError}
            issinup
            disableButtondata={isError || !email}
            disabled={isError || !email}
            textstyle={[
              !isError ? styles.btntextstyle : styles.disableBtnStyle,
              { lineHeight: 36, marginTop: 0 },
            ]}
            onPress={() => handleNext(email, password)}
          />
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default LoginExternal;
