import AsyncStorage from "@react-native-async-storage/async-storage";
import { getPoolByOrganisationId } from "../actions";
import { CognitoUserPool } from "amazon-cognito-identity-js";

var InternalUserPool = null;

async function CreateUserPool() {
  var organisationId = await AsyncStorage.getItem("organisation");
  var pool = null;
  if (InternalUserPool == null) {
    pool = await getPoolByOrganisationId(organisationId);
    await AsyncStorage.setItem("userpool", JSON.stringify(pool.data));
  }
  InternalUserPool = new CognitoUserPool(pool.data);
  return InternalUserPool;
}

export const getPool = async () => {
  let poolData = {};
  if (InternalUserPool == null) {
    poolData = await CreateUserPool();
  }
  const pool = await AsyncStorage.getItem("userpool");

  return InternalUserPool;
};
