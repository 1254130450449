import React from "react";
import { View, Platform, SafeAreaView, StatusBar, Image } from "react-native";
import {
  AntDesign,
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
  Entypo,
  FontAwesome5,
} from "@expo/vector-icons";
import styles from "../components/style/styles";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");

const ClothBagCollection = ({ navigation }) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.bagCollectionContainer}>
          <View style={styles.bagItemContainer}>
            <CoreText
              style={styles.clothItemText}
              textTitle="Dina plagg"
              fontType={MontserratBold}
            />
            <View style={styles.clothItemImage}>
              <Image
                style={styles.clothImageStyle}
                source={require("../assets/images/person_outline.png")}
              />
            </View>
          </View>

          <View style={styles.productDetailSection}>
            <TouchableOpacity
              onPress={() => navigation.navigate("ItemDetails")}
            >
              <Image
                style={styles.productImage}
                source={require("../assets/images/Hoodie.png")}
              />
            </TouchableOpacity>
            <View style={{ flexDirection: "column" }}>
              <View>
                <CoreText
                  style={styles.productDetails}
                  textTitle="Haglöfs"
                  fontType={MontserratBold}
                />
              </View>
              <View>
                <CoreText
                  FirstTitle="Herr "
                  lastTitle=" Jackor"
                  icon={
                    <AntDesign
                      name="right"
                      size={9}
                      color="rgba(15, 15, 15, 0.4)"
                    />
                  }
                  style={[styles.headerTitle, { color: "rgba(0, 0, 0, 0.48)" }]}
                  fontType={MontserratLight}
                />
              </View>
              <View style={styles.consideredByStore}>
                <CoreText
                  style={styles.storeText}
                  textTitle="Övervägs av butik"
                  fontType={MontserratBold}
                />
              </View>
            </View>
          </View>

          <View style={styles.maphorizontalline} />

          <View style={styles.addOtherItemSection}>
            <View style={styles.otherItemIcon}>
              <MaterialCommunityIcons name="hanger" size={40} color="#E2017B" />
            </View>
            <View style={styles.addOtherProduct}>
              <View>
                <CoreText
                  style={styles.productDetails}
                  textTitle="Houdini"
                  fontType={MontserratBold}
                />
              </View>
              <View>
                <CoreText
                  FirstTitle="Herr "
                  lastTitle=" Tröjor"
                  icon={
                    <AntDesign
                      name="right"
                      size={9}
                      color="rgba(15, 15, 15, 0.4)"
                      onPress={() => navigation.goBack()}
                    />
                  }
                  style={[styles.headerTitle, { color: "rgba(0, 0, 0, 0.48)" }]}
                  fontType={MontserratLight}
                />
              </View>
              <View style={{ marginTop: 10 }}>
                <CoreText
                  style={styles.stepLeftText}
                  textTitle="2 steg kvar"
                  fontType={MontserratBold}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.footerMenuSection}>
        <View>
          <Ionicons name="ios-home-sharp" size={24} color="black" />
        </View>
        <View>
          <MaterialIcons name="insert-chart-outlined" size={24} color="black" />
        </View>
        <View style={styles.addItemIcon}>
          <Entypo name="plus" size={24} color="white" />
        </View>
        <View>
          <FontAwesome5 name="bell" size={24} color="black" />
        </View>
        <View>
          <Ionicons name="ios-menu-sharp" size={24} color="black" />
        </View>
      </View>
    </SafeAreaView>
  );
};
export default ClothBagCollection;
