import React from "react";
import { View, Image } from "react-native";
// import Slick from "react-native-slick";
import styles from "../../components/style/styles.js";

const SwiperRetailItem = () => {
  return (
    <>
      <View>
        <View
          height={450}
          dot={<View style={styles.retailDotStyle} />}
          activeDot={<View style={styles.activeDotStyle} />}
          paginationStyle={{
            right: 22,
            bottom: 22,
            left: null,
          }}
          loop={false}
        >
          <Image
            source={require("../../assets/images/newJacket.png")}
            style={styles.swipeImage}
          />
          <Image
            source={require("../../assets/images/newjacketBack.png")}
            style={styles.swipeImage}
          />
          <Image
            source={require("../../assets/images/newJacket.png")}
            style={styles.swipeImage}
          />
        </View>
      </View>
    </>
  );
};

export default SwiperRetailItem;
