import React from "react";
import pin from "../../assets/images/pin.png";
import GoogleMapReact from "google-map-react";

const markerStyle = {
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, -100%)",
};

const AnyReactComponent = ({ text }) => (
  <img style={markerStyle} src={pin} alt="pin" />
);

export default function WebMap({ service }) {
  const { easting, northing } = service?.coordinates?.[0] || {
    easting: 13.543697,
    northing: 57.303478,
  };
  const defaultProps = {
    center: {
      lat: northing,
      lng: easting,
    },
    zoom: 11,
  };

  return (
    <div
      style={{
        height: "20vh",
        width: "100%",
        overflow: "hidden",
        borderRadius: 20,
      }}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={{ lat: northing, lng: easting }}
      >
        <AnyReactComponent lat={northing} lng={easting} />
      </GoogleMapReact>
    </div>
  );
}
