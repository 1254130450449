import React from "react";
import { View } from "react-native";
// import Slick from "react-native-slick";
import { AntDesign, Ionicons, FontAwesome } from "@expo/vector-icons";
import { Card, Title } from "react-native-paper";
import Colors from "../../constants/Colors";
import CoreText from "../core/CoreText";
import styles from "../../components/style/styles.js";

const MontserratRegular = require("../../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../../assets/Font/Montserrat-Bold.ttf");

const SwiperAccept = () => {
  return (
    <>
      <View>
        <View
          height={450}
          dot={<View style={styles.dotStyle} />}
          activeDot={<View style={styles.activeDotStyle} />}
          paginationStyle={{
            justifyContent: "center",
            alignSelf: "center",
            flex: 1,
          }}
          loop={false}
        >
          <View style={{ marginTop: 100 }}>
            <Card style={[styles.cardStyle, styles.darkThemeCard]}>
              <Title>
                <CoreText
                  textTitle="Vi tar emot"
                  style={[styles.headerTitle, { color: Colors.WHITE }]}
                  fontType={MontserratBold}
                />
              </Title>
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.leftStyle}
                title={
                  <CoreText
                    textTitle="Kläder från märken som vi säljer i våra butiker"
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <Ionicons
                    name="checkmark-circle-sharp"
                    style={[styles.icon, { marginTop: -2 }]}
                    size={18}
                    color={Colors.GREEN}
                  />
                )}
              />
              <Card.Title
                style={styles.cardTitleStyle}
                leftStyle={styles.otherLeftStyle}
                title={
                  <CoreText
                    textTitle="Hela, varsamt använda kläder."
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <Ionicons
                    name="checkmark-circle-sharp"
                    style={[styles.icon, { marginTop: 2 }]}
                    size={18}
                    color={Colors.GREEN}
                  />
                )}
              />
              <View
                style={[
                  styles.maphorizontalline,
                  { borderBottomColor: "rgba(255,255,255,0.08)" },
                ]}
              />
              <Title>
                <CoreText
                  textTitle="Vi tar inte emot"
                  style={[styles.headerTitle, { color: Colors.WHITE }]}
                  fontType={MontserratBold}
                />
              </Title>
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.otherLeftStyle}
                title={
                  <CoreText
                    textTitle="Trasiga, fläckiga kläder"
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <AntDesign
                    name="closecircle"
                    size={16}
                    style={[{ marginTop: 2 }]}
                    color={Colors.RED}
                  />
                )}
              />
            </Card>
          </View>
          <View style={{ marginTop: 40 }}>
            <Card style={[styles.cardStyle, styles.darkThemeCard]}>
              <Title>
                <CoreText
                  textTitle="Villkor"
                  style={[styles.headerTitle, { color: Colors.WHITE }]}
                  fontType={MontserratBold}
                />
              </Title>
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.leftStyle}
                title={
                  <CoreText
                    textTitle="Begagnade plagg säljer generellt för 50% av sitt originalpris."
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <FontAwesome
                    name="circle"
                    style={[styles.icon, { marginTop: -2 }]}
                    size={10}
                    color={Colors.WHITE}
                  />
                )}
              />
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.leftStyle}
                title={
                  <CoreText
                    textTitle="Du som säljare får 40% av priset i kommission vid såld vara."
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <FontAwesome
                    name="circle"
                    style={[styles.icon, { marginTop: -2 }]}
                    size={10}
                    color={Colors.WHITE}
                  />
                )}
              />
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.leftStyle}
                title={
                  <CoreText
                    textTitle="Efter 7 dagar sänks priset med 5%."
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                left={() => (
                  <FontAwesome
                    name="circle"
                    style={[styles.icon, { marginTop: 15 }]}
                    size={10}
                    color={Colors.WHITE}
                  />
                )}
              />
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.leftStyle}
                title={
                  <CoreText
                    textTitle="Efter 30 dagar kan plagget återhämtas i butiken."
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <FontAwesome
                    name="circle"
                    style={[styles.icon, { marginTop: -2 }]}
                    size={10}
                    color={Colors.WHITE}
                  />
                )}
              />
              <Card.Title
                subtitleNumberOfLines={2}
                style={styles.cardTitleStyle}
                leftStyle={styles.leftStyle}
                title={
                  <CoreText
                    textTitle="Efter 40 dagar doneras plagget till välgörenhet."
                    style={[styles.subtitleStyle, { color: Colors.OFFWHITE }]}
                    fontType={MontserratRegular}
                  />
                }
                titleNumberOfLines={2}
                left={() => (
                  <FontAwesome
                    name="circle"
                    style={[styles.icon, { marginTop: -2 }]}
                    size={10}
                    color={Colors.WHITE}
                  />
                )}
              />
            </Card>
          </View>
        </View>
      </View>
    </>
  );
};

export default SwiperAccept;
