import React from "react";
import { TouchableOpacity as TouchableButton } from "react-native";
import CoreText from "../components/core/CoreText";
import styles from "../components/style/styles.js";
import { useSelector } from "react-redux";
import Colors from "../constants/Colors";
import CoreLoader from "../components/core/CoreLoader";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const Button = (props) => {
  const orgDetail = useSelector((state) => state.prodReducer.products);

  const {
    style,
    btnTitle,
    onPress,
    textstyle,
    lineHeight,
    isLoad,
    isError,
    capturedImage,
    image,
    issinup,
    disableButtondata,
    disabled,
    iconFront,
    classStyle,
  } = props;

  const btnstyle = (() => {
    if (!isError && issinup) {
      return [
        styles.signUpButton,
        {
          backgroundColor: orgDetail?.[0]?.primaryColor || Colors.BROWN,
        },
      ];
    } else if (disableButtondata) {
      return [
        styles.disableButton,
        { backgroundColor: orgDetail?.[0]?.primaryColor || Colors.BROWN },
      ];
    } else if (capturedImage || image) {
      return [
        styles.button,
        {
          backgroundColor: orgDetail?.[0]?.primaryColor || Colors.BROWN,
        },
      ];
    } else {
      return [
        styles.button,
        {
          backgroundColor: orgDetail?.[0]?.primaryColor || Colors.BROWN,
        },
      ];
    }
  })();

  return (
    <TouchableButton
      style={[style, btnstyle, classStyle]}
      onPress={onPress}
      disabled={disabled}
    >
      {isLoad ? (
        <CoreLoader
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
          isButton
        />
      ) : (
        <CoreText
          textTitle={btnTitle}
          style={[styles.btntextstyle, { lineHeight: lineHeight }, textstyle]}
          fontType={MontserratMedium}
          iconFront={iconFront}
        />
      )}
    </TouchableButton>
  );
};

export default Button;
