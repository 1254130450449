import React, { useState } from "react";
import { Col, Grid } from "react-native-easy-grid";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
  Text,
  Image,
} from "react-native";
import { MaterialIcons, AntDesign } from "@expo/vector-icons";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import MapView, {
  Marker,
  PROVIDER_GOOGLE,
  Polyline,
  Callout,
} from "react-native-maps";
import Colors from "../constants/Colors";
import { mapStyle } from "../constants/mapStyle";

const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const DropOffBagItem = ({ navigation }) => {
  const [coordinates] = useState([
    {
      latitude: 59.340946,
      longitude: 18.0855,
    },
    {
      latitude: 59.36965,
      longitude: 18.075095,
    },
  ]);
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <ScrollView>
        <View style={styles.Landingcontainer}>
          <View style={[styles.mapLocation, styles.dropOffMapSection]}>
            <View style={[styles.dotMenuIcon, styles.closeIconPosition]}>
              <AntDesign name="close" size={24} color={Colors.WHITE} />
            </View>
            <MapView
              style={[styles.dropOffItemMap, { position: "relative" }]}
              loadingEnabled={true}
              provider={PROVIDER_GOOGLE}
              customMapStyle={mapStyle}
              initialRegion={{
                latitude: coordinates[0].latitude,
                longitude: coordinates[0].longitude,
                latitudeDelta: 0.0922,
                longitudeDelta: 0.0421,
              }}
            >
              <Marker coordinate={coordinates[0]}>
                <View>
                  <Image
                    style={{
                      height: 20,
                      width: 20,
                    }}
                    source={require("../assets/images/source.png")}
                  />
                </View>
              </Marker>
              <Marker coordinate={coordinates[1]}>
                <MaterialIcons
                  name="location-pin"
                  size={30}
                  color={Colors.BLACK}
                />
                <Callout tooltip>
                  <View style={{ backgroundColor: "#ffffff" }}>
                    <Text style={styles.callouttolltip}>Reused Outdoor</Text>
                  </View>
                </Callout>
              </Marker>
              <Polyline
                coordinates={coordinates}
                strokeColor="#000"
                strokeWidth={5}
              />
            </MapView>
          </View>
          <View style={styles.dropItemAddressSection}>
            <View style={[styles.produdtDetailsContainer, { marginBottom: 0 }]}>
              <View>
                <CoreText
                  style={styles.productDetails}
                  textTitle="Reused outdoor"
                  fontType={MontserratBold}
                />
                <CoreText
                  style={styles.productAddress}
                  textTitle="Sibyllegatan 47, 114 42 Stockholm"
                  fontType={MontserratRegular}
                />
              </View>
              <View>
                <CoreText
                  style={[styles.productAddress, { marginTop: 0 }]}
                  textTitle="900m"
                  fontType={MontserratRegular}
                />
              </View>
            </View>
            <View style={styles.maphorizontalline} />
            <View>
              <CoreText
                style={styles.productDetails}
                textTitle="Öppettider"
                fontType={MontserratBold}
              />
              <Grid>
                <Col>
                  <CoreText
                    style={styles.weekDays}
                    textTitle="Måndag-Fredag"
                    fontType={MontserratRegular}
                  />
                  <CoreText
                    style={styles.weekDays}
                    textTitle="Lördag-Söndag"
                    fontType={MontserratRegular}
                  />
                </Col>
                <Col>
                  <CoreText
                    style={styles.timing}
                    textTitle="10:00 - 19:00"
                    fontType={MontserratRegular}
                  />
                  <CoreText
                    style={styles.timing}
                    textTitle="11:00 - 17:00"
                    fontType={MontserratRegular}
                  />
                </Col>
              </Grid>
            </View>
            <View style={styles.maphorizontalline} />
            <View style={[styles.DropcardContainer, styles.bagItem]}>
              <View style={{ width: 270 }}>
                <CoreText
                  style={styles.dropOffInfoText}
                  textTitle="Glöm inte bort att visa din QR-kod när du lämnar ditt plagg i butiken."
                  fontType={MontserratRegular}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View style={styles.footerButton}>
        <TouchableOpacity
          style={[styles.button, styles.bagItemButton]}
          onPress={() => navigation.navigate("SoldClothBagCollection")}
        >
          <CoreText
            textTitle="Visa QR-kod"
            style={[
              styles.btntextstyle,
              { lineHeight: 30, color: Colors.BLACK },
            ]}
            fontType={MontserratBold}
          />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};
export default DropOffBagItem;
