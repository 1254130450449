import React from "react";
import { View, Image, Platform, SafeAreaView, StatusBar } from "react-native";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import Button from "../components/Button";
import Header from "../components/core/Header";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");

const AddItemSuccess = ({ navigation, route }) => {
  const { t } = useTranslation();
  const pickUser = route.params.organisationId;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Header navigation={navigation} route={route} isHeader />
      <View
        style={[
          styles.Landingcontainer,
          styles.justifyContent,
          styles.alignItems,
        ]}
      >
        <View style={styles.blockdata}>
          <View style={styles.successimageContainer}>
            <Image
              style={styles.imgbanner}
              source={require("../assets/images/itemsuccess.png")}
            />
          </View>
        </View>

        <View style={styles.optionBody}>
          <View style={styles.contentHeaderBody}>
            <CoreText
              textTitle={t("app.itemSuccess_title", "Plagget övervägs!")}
              style={styles.titleHeader}
              fontType={MontserratMedium}
            />
            <CoreText
              textTitle={t("app.itemSuccess_desc", "Vi säkerställer att plagget passar vårt sortiment och hör av oss till dig så fort vi kan!")}
              style={[
                styles.AddsubTitle,
                {
                  textAlign: "center",
                  display: "flex",
                },
              ]}
              fontType={MontserratMedium}
            />
          </View>
        </View>
        <View style={[styles.buttonContainer, { marginBottom: 30 }]}>
          <Button
            btnTitle={t("app.itemSuccess_button", "Lägg till ännu ett plagg")}
            onPress={() =>
              navigation.navigate("LandingCritera", {
                organisationId: pickUser,
              })
            }
            lineHeight={30}
          />
        </View>
      </View>
      <CoreFooter />
    </SafeAreaView>
  );
};
export default AddItemSuccess;
