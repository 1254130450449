import React, { useState, useEffect } from "react";
import { View, Platform, SafeAreaView, StatusBar, Image } from "react-native";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import styles from "../components/style/styles";
import {
  getArticlesByCustomerId,
  getComission,
  getCustomerDetails,
} from "../actions";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import CoreLoader from "../components/core/CoreLoader";
import Header from "../components/core/Header";
import CoreBagCollectionHeader from "../components/core/CoreBagCollectionHeader";

const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratLight = require("../assets/Font/Montserrat-Light.ttf");
import { signOut } from "../components/core/CoreSignOut";

const SoldClothBagCollectionIntegration = ({ navigation, route }) => {
  const pickOrg = "arkivet";
  const customers = useSelector((state) => state.prodReducer.customerDetail);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [comission, setComission] = useState([]);
  const [loadItem, setLoadItem] = useState(true);
  const orgDetail = useSelector((state) => state.prodReducer.products);

  const [soldItems, setSoldItems] = useState([]);
  const [pendingItems, setPendingItems] = useState([]);
  const [actionRequriedItems, setActionRequriedItems] = useState([]);

  useEffect(async () => {
    getCustomerDetails().then(({ data }) => {
      setCustomerDetails(data);
      setComission(data.comission);
      getComission().then(({ data }) => {
        setComission(data.body);
      });
    });

    getArticlesByCustomerId()
      .then(({ data }) => {
        var tmpSold = [];
        var tmpPending = [];
        var tmpActionRequried = [];
        console.log(data);

        data.articleSelling.forEach((element) => {
          element.label =
            element.daysLeft + " dagar kvar";
          tmpPending.push(element);
        });

        data.articleSold.forEach((element) => {
          element.label =
            "Plagget är sålt, din komission är " + element.comission + " kr";
          tmpSold.push(element);
        });

        setSoldItems(tmpSold);
        setPendingItems(tmpPending);
        //setActionRequriedItems(tmpActionRequried);

        //setSoldItems(data.articleSelling);
        //setPendingItems(data.articleSold);
        // Not implemented:  setActionRequriedItems(tmpActionRequried);
        setLoadItem(false);
      })
      .catch((error) => { });
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {loadItem ? (
        <CoreLoader
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <>
          <Header navigation={navigation} route={route} isHomePage isHeader />

          <ScrollView>
            <View>
              <View style={styles.coreBagHeader}>
                <CoreBagCollectionHeader isHeaderImage />
              </View>
            </View>
            <View style={[styles.availableProduct]}>
              <CoreText
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                }}
                textTitle="Mina inlämmningar"
                fontType={MontserratLight}
              />
              <View style={{ marginVertical: 20 }}>
                <CoreText
                  style={[styles.clothItemText, { fontSize: 16 }]}
                  textTitle="Till försäljning"
                  fontType={MontserratBold}
                />
              </View>
              {pendingItems?.map((item, index, array) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#F9F6F1",
                    borderRadius: 20,
                    marginBottom: 10,
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                  }}
                >
                  <View style={[styles.productDetailSection, { width: 0 }]}>
                    <Image
                      style={styles.productImage}
                      source={require("../assets/images/checkroom.svg")}
                    />
                    <View style={{ flexDirection: "column", width: 180 }}>
                      <View>
                        <CoreText
                          style={[
                            styles.productDetails,
                            { fontSize: 14, marginBottom: 0 },
                          ]}
                          textTitle={item.title}
                          fontType={MontserratBold}
                        />
                      </View>
                      <View>
                        <CoreText
                          dropOffItem
                          FirstTitle={item.amount}
                          lastTitle="SEK"
                          icon={
                            <AntDesign
                              name="right"
                              size={9}
                              color="rgba(15, 15, 15, 0.4)"
                            />
                          }
                          style={[
                            styles.headerTitle,
                            { color: "rgba(0, 0, 0, 0.48)", fontSize: 12 },
                          ]}
                          fontType={MontserratLight}
                        />
                      </View>
                      <View
                        style={[
                          styles.consideredByStore,
                          {
                            marginTop: 4,
                            backgroundColor: Colors.WHITE,
                            borderRadius: 8,
                          },
                        ]}
                      >
                        <CoreText
                          style={styles.storeText}
                          textTitle={item.label}
                          fontType={MontserratBold}
                        />
                      </View>
                    </View>
                    <View style={{ justifyContent: "center" }}>
                      <MaterialIcons
                        name="keyboard-arrow-right"
                        size={30}
                        color="black"
                      />
                    </View>
                  </View>
                </View>
              ))}
            </View>

            {/* Below are items that are SOLD ITEMS */}
            <View style={[styles.availableProduct]}>
              <View style={{ marginVertical: 20 }}>
                <CoreText
                  style={[styles.clothItemText, { fontSize: 16 }]}
                  textTitle="Sålda artiklar"
                  fontType={MontserratBold}
                />
              </View>
              {soldItems?.map((item, index, array) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: "#F9F6F1",
                    borderRadius: 20,
                    marginBottom: 10,
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                  }}
                >
                  <View style={[styles.productDetailSection, { width: 0 }]}>
                    <TouchableOpacity>
                      <Image
                        style={[styles.productImage, { marginRight: 20 }]}
                        source={{ uri: item?.photo?.[0]?.url }}
                      />
                    </TouchableOpacity>
                    <View style={{ flexDirection: "column", width: 180 }}>
                      <View>
                        <CoreText
                          style={[
                            styles.productDetails,
                            { fontSize: 14, marginBottom: 0 },
                          ]}
                          textTitle={item.title}
                          fontType={MontserratBold}
                        />
                      </View>
                      <View
                        style={[
                          styles.consideredByStore,
                          {
                            marginTop: 4,
                            backgroundColor: Colors.WHITE,
                            borderRadius: 8,
                          },
                        ]}
                      >
                        <CoreText
                          style={styles.storeText}
                          textTitle={item.label}
                          fontType={MontserratBold}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
};
export default SoldClothBagCollectionIntegration;
