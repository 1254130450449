import React from "react";
import {
  TouchableOpacity,
  View,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  ScrollView,
} from "react-native";
import {
  AntDesign,
  MaterialCommunityIcons,
  Ionicons,
} from "@expo/vector-icons";
import styles from "../components/style/styles";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");

const AddItemSuccess = ({ navigation }) => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        backgroundColor: Colors.BLACK,
      }}
    >
      <ScrollView>
        <View style={styles.iconContainer}>
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={24}
            color="rgba(255, 255, 255, 0.4)"
          />
          <AntDesign name="close" size={24} color="rgba(255, 255, 255, 0.4)" />
        </View>
        <View
          style={[
            styles.Landingcontainer,
            styles.justifyContent,
            styles.alignItems,
          ]}
        >
          <View>
            <View style={styles.bookedBag}>
              <Ionicons
                name="checkmark-circle-sharp"
                style={styles.checkmarkCircle}
                size={40}
                color={Colors.WHITE}
              />
              <Image
                style={styles.imgbanner}
                source={require("../assets/images/airport_shuttle.png")}
              />
            </View>
          </View>

          <View style={styles.optionBody}>
            <View style={styles.contentHeaderBody}>
              <CoreText
                textTitle="Din påse är påväg!"
                style={[styles.titleHeader, { color: Colors.WHITE }]}
                fontType={MontserratMedium}
              />
              <CoreText
                textTitle="Fortsätt till Serculate appen för att se när din påse har tagits emot."
                style={[styles.AddsubTitle, { color: Colors.WHITE }]}
                fontType={MontserratMedium}
              />
            </View>
          </View>
        </View>
      </ScrollView>

      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: Colors.WHITE }]}
          onPress={() => navigation.navigate("ClothBagCollection")}
        >
          <CoreText
            textTitle="Lägg till ännu ett plagg"
            style={[
              styles.btntextstyle,
              { lineHeight: 30, color: Colors.BLACK },
            ]}
            fontType={MontserratBold}
          />
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
};
export default AddItemSuccess;
