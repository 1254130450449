const tintColorLight = "#E2017B";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#0F0F0F",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
  BACKGROUND_COLOR_RUBY: "#E2017B",
  GRYSIX: "#0F0F0F",
  GRAY: "#878787",
  DARK_GRAY: "#333333",
  LIGHT_GRAY: "#ECECEC",
  LIGHTEN_GRAY: "#ebebeb",
  LIGHTEST_GRAY: "rgba(15, 15, 15, 0.24)",
  LIGHT_YELLOW: "#F6F5F1",

  TEXT_GRAY: "rgba(0, 0, 0, 0.4)",
  LIGHT_TEXT_GRAY: "rgba(0, 0, 0, 0.04)",
  DARK_TEXT_GRAY: "rgba(15, 15, 15, 0.6)",
  OFFWHITE: "rgba(255,255,255,0.72)",
  GREEN: "#51B066",
  RED: "#D25757",
  WHITE: "#FFFFFF",
  LIGHT_WHITE: "rgba(0, 0, 0, 0.08)",
  BLACK: "#000000",
  BROWN: "#3A2104",
  FONT_BLACK: "#121212",
  DISABLE_BTN: "#DDDDDD",
};
