import React, { useState } from "react";
import {
  View,
  Platform,
  SafeAreaView,
  StatusBar,
  TouchableOpacity,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { List, Provider, Portal, TextInput, Modal } from "react-native-paper";
import styles from "../components/style/styles";
import { ScrollView } from "react-native-gesture-handler";
import CoreText from "../components/core/CoreText";
import Colors from "../constants/Colors";
import { useSelector } from "react-redux";
import Header from "../components/core/Header";
import Button from "../components/Button";
import { AntDesign } from "@expo/vector-icons";
import CoreFooter from "../components/core/CoreFooter";
import { useTranslation } from 'react-i18next';

const MontserratMedium = require("../assets/Font/Montserrat-Medium.ttf");
const MontserratBold = require("../assets/Font/Montserrat-Bold.ttf");
const MontserratRegular = require("../assets/Font/Montserrat-Regular.ttf");

const Additemoption = ({ navigation, route }) => {
  const { t } = useTranslation();
  const [inputData, setInputData] = useState(false);
  const [categorieData, setCategorieData] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const pickOrg = route.params.organisationId;
  const gender = route.params?.gender;
  const categories = useSelector((state) => state.prodReducer.categories);
  const orgDetail = useSelector((state) => state.prodReducer.products);
  AsyncStorage.setItem("categorie", categorieData);

  const getcategory = {};
  categories.map((item) => {
    if (getcategory[item.type]) {
      getcategory[item.type] = [...getcategory[item.type], item.brand];
    } else {
      getcategory[item.type] = [item.brand];
    }
  });

  const customPageList = orgDetail[0]?.customPages
    ? JSON.parse(orgDetail[0]?.customPages)
    : {};

  const handleNext = (item) => {
    let pageUrl = "AddItemSearch"
    if (orgDetail[0]?.urlPath === 'sandqvist') {
      // if (customPageList?.isHideSearch && customPageList?.isHidePrice) {
      //   pageUrl = "AddItemProductType"
      // } else 
      if (customPageList?.isHideSearch) {
        pageUrl = "AddItemProductType"
      } else {
        pageUrl = "AddItemSearch"
      }
    }
    navigation.navigate(pageUrl, {
      gender,
      clothType: item,
      organisationId: pickOrg,
    });
  };

  const hideButtonModal = () => setVisibleModal(false);
  const additemoption_desc = "Vi söker bara plaggen som finns listade här - Om du skickar in något annat så kommer det antagligen be nekat då det inte passar vårat sortiment"
  return (
    <Provider>
      <SafeAreaView
        style={{
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        }}
      >
        <Header
          navigation={navigation}
          route={route}
          progress={0.4}
          steps={2}
        />
        <ScrollView>
          <View style={styles.Landingcontainer}>
            <View style={styles.AddcontainerBody}>
              <View style={styles.titleContainer}>
                <CoreText
                  textTitle={t("app.Additemoption_title", "Vilken typ är plagget?")}
                  style={[
                    styles.Title,
                    {
                      lineHeight: 32,
                      paddingTop: 0,
                      marginTop: 24,
                      color: orgDetail[0]?.secondaryColor || Colors.TEXT_GRAY,
                    },
                  ]}
                  fontType={MontserratMedium}
                />
              </View>
              <View style={[styles.mainContent, { paddingTop: 30 }]}>
                {Object.keys(getcategory).map((key, index) => (
                  <List.Section key={index}>
                    <List.Accordion
                      style={[styles.accordion, { borderRadius: 0 }]}
                      expanded={true}
                      title={
                        <CoreText
                          textTitle={key}
                          style={[
                            styles.accordionTitle,
                            { color: Colors.BLACK },
                          ]}
                          fontType={MontserratMedium}
                        />
                      }
                      theme={{ colors: { text: Colors.BLACK } }}
                    >
                      {getcategory[key]?.map((item, index) => (
                        <List.Item
                          key={index}
                          title={
                            <CoreText
                              textTitle={item}
                              style={[
                                styles.accordionTitle,
                                { lineHeight: 24 },
                              ]}
                              fontType={MontserratBold}
                            />
                          }
                          style={styles.listitems}
                          right={(props) => item.icon}
                          // onPress={() =>
                          //   navigation.navigate("AddItemSearch", {
                          //     gender,
                          //     clothType: item,
                          //     organisationId: pickOrg,
                          //   })
                          // }
                          onPress={() => handleNext(item)}
                        />
                      ))}
                    </List.Accordion>
                  </List.Section>
                ))}
              </View>
            </View>
            <Portal>
              <Modal
                visible={visibleModal}
                onDismiss={hideButtonModal}
                contentContainerStyle={{
                  backgroundColor: "white",
                  marginVertical: 0,
                  marginHorizontal: 15,
                  borderRadius: 10,
                  alignSelf: "center",
                  maxWidth: 400,
                }}
              >
                <View
                  style={{
                    textAlign: "end",
                    paddingHorizontal: 10,
                    paddingTop: 10,
                  }}
                >
                  <AntDesign
                    name="closesquareo"
                    size={24}
                    color="black"
                    onPress={() => hideButtonModal()}
                  />
                </View>
                <View style={{ paddingVertical: 15, paddingHorizontal: 15 }}>
                  <CoreText
                    textTitle={t("app.additemoption_desc", additemoption_desc)}
                    style={styles.codeWebDesign}
                    fontType={MontserratMedium}
                  />
                </View>
                <View
                  style={{
                    paddingBottom: 15,
                    paddingHorizontal: 15,
                    display: "flex",
                    alignItems: "end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setInputData(true);
                      setVisibleModal(false);
                    }}
                  >
                    <CoreText
                      textTitle="OK"
                      fontType={MontserratMedium}
                      style={styles.butttonOkData}
                    />
                  </TouchableOpacity>
                </View>
              </Modal>
            </Portal>
            <View style={{ paddingHorizontal: 20, marginBottom: 20 }}>
              <TouchableOpacity
                onPress={() => {
                  setVisibleModal(true);
                }}
                disabled={inputData}
              >
                <CoreText
                  textTitle={t("app.other_garment", "Annat plagg")}
                  style={[
                    styles.listitems,
                    styles.categorieData,
                    { marginTop: 0 },
                  ]}
                  fontType={MontserratMedium}
                />
              </TouchableOpacity>
              {inputData && (
                <>
                  <TextInput
                    label={
                      <CoreText
                        textTitle={t("app.garment_type", "Skriv vad det är för plaggtyp")}
                        style={[styles.input]}
                        fontType={MontserratRegular}
                        isInput
                      />
                    }
                    style={[styles.inputFiledstyle, { marginVertical: 25 }]}
                    value={categorieData}
                    keyboardType="default"
                    placeholderTextColor="#000"
                    underlineColor="transparent"
                    activeUnderlineColor="transparent"
                    maxLength={200}
                    onChange={(e) => {
                      setCategorieData(e.target.value);
                    }}
                    theme={{ colors: { text: Colors.BLACK } }}
                  />
                  <Button
                    btnTitle={t("app.continue", "Fortsätt")}
                    lineHeight={32}
                    style={{ marginBottom: 30 }}
                    // onPress={() =>
                    //   navigation.navigate("AddItemSearch", {
                    //     gender,
                    //     clothType: categorieData,
                    //     organisationId: pickOrg,
                    //   })
                    // }
                    onPress={() => handleNext(categorieData)}
                    disableButtondata={!categorieData}
                    disabled={!categorieData}
                  />
                </>
              )}
            </View>
          </View>
        </ScrollView>
        <CoreFooter />
      </SafeAreaView>
    </Provider>
  );
};
export default Additemoption;
