import React, { useState } from "react";
import { View, Image } from "react-native";
import { TextInput, Searchbar } from "react-native-paper";
import { AntDesign, SimpleLineIcons, EvilIcons } from "@expo/vector-icons";
import styles from "./style/styles";
import CoreText from "./core/CoreText";
import Colors from "../constants/Colors";
import Picker from "./Picker";
import { Languages } from "../constants/Languages";
import { countryCurrency } from "../constants/CountryCurrency";

const AssistantMedium = require("../assets/Font/Assistant-Medium.ttf");
const AssistantRegular = require("../assets/Font/Assistant-Regular.ttf");

const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setErrorEmail] = useState("");
  const [isError, setIsError] = useState(false);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const handleEmailChange = (emailValue) => {
    if (reg.test(emailValue) === false) {
      setIsError(true);
      setErrorEmail("Email is Not Correct");
      setEmail(emailValue);
    } else {
      setIsError(false);
      setEmail(emailValue);
      setErrorEmail("");
    }
  };

  const handleEmailValidation = () => {
    if (email.length === 0) {
      setIsError(true);
      setErrorEmail("Please Enter Email");
    } else if (reg.test(email) === false) {
      setIsError(true);
      setErrorEmail("Email is Not Correct");
    } else if (reg.test(email) === true) {
      setIsError(false);
      setErrorEmail("");
    }
  };

  return (
    <View>
      <View style={[styles.horizontalRule, { marginBottom: 12 }]} />
      {footerMenu.map((item, index) => (
        <View style={styles.menuContainer} key={index}>
          <CoreText
            textTitle={item.menu}
            style={styles.menuText}
            fontType={AssistantMedium}
          />
          <CoreText
            textTitle={item.submenu1}
            style={styles.subMenuText}
            fontType={AssistantMedium}
          />
          <CoreText
            textTitle={item.submenu2}
            style={styles.subMenuText}
            fontType={AssistantMedium}
          />
          <CoreText
            textTitle={item.submenu3}
            style={styles.subMenuText}
            fontType={AssistantMedium}
          />
          {item.submenu4 && (
            <CoreText
              textTitle={item.submenu4}
              style={styles.subMenuText}
              fontType={AssistantMedium}
            />
          )}
        </View>
      ))}

      <View style={styles.menuContainer}>
        <CoreText
          textTitle="Our mission"
          style={styles.menuText}
          fontType={AssistantMedium}
        />
        <CoreText
          textTitle="Quality materials, good designs, craftsmanship and sustainability."
          style={styles.subMenuText}
          fontType={AssistantMedium}
        />
      </View>

      <View style={{ marginTop: 24 }}>
        <View style={[styles.emailContainer, { alignItems: "center" }]}>
          <CoreText
            textTitle="Subscribe to our emails"
            style={[styles.subTitle, { color: Colors.FONT_BLACK }]}
            fontType={AssistantMedium}
          />
        </View>
        <View style={styles.emailContainer}>
          <TextInput
            label={
              <CoreText
                textTitle="Email"
                style={styles.input}
                fontType={AssistantMedium}
              />
            }
            style={[styles.collectionInputFiled]}
            value={email}
            onChangeText={(text) => handleEmailChange(text)}
            placeholderTextColor="rgba(18, 18, 18, 0.75)"
            underlineColor="transparent"
            activeUnderlineColor="transparent"
          />
          <AntDesign
            name="arrowright"
            size={24}
            color="black"
            style={styles.arrowrightIcon}
            onPress={handleEmailValidation}
          />
          {emailError ? (
            <CoreText
              textTitle={emailError}
              style={[
                styles.subTitle,
                { color: Colors.RED, fontSize: 10, paddingTop: 0 },
              ]}
              fontType={AssistantMedium}
            />
          ) : (
            <CoreText
              textTitle={""}
              style={[
                styles.subTitle,
                { color: Colors.RED, fontSize: 10, paddingTop: 0 },
              ]}
              fontType={AssistantMedium}
            />
          )}
        </View>

        <View style={styles.socialIconContainer}>
          {socialIcon.map((item, index) =>
            index === 3 ? (
              <Image
                key={index}
                style={{
                  width: 15,
                  height: 18,
                  marginRight: 25,
                  marginTop: 2,
                }}
                source={item.icon}
              />
            ) : (
              <AntDesign
                key={index}
                name={item.icon}
                size={18}
                color="black"
                style={{ marginRight: 25 }}
              />
            )
          )}
        </View>
        <View style={[styles.horizontalRule, { marginBottom: 12 }]} />
        <View style={{ marginHorizontal: 63.5 }}>
          <View style={styles.poweredBySection}>
            <View style={{ marginRight: 5, width: 139 }}>
              <CoreText
                style={[
                  styles.filterTitle,
                  { marginBottom: 12, color: "rgba(18, 18, 18, 0.75)" },
                ]}
                textTitle="Country/region"
                fontType={AssistantRegular}
              />
              <Picker items={countryCurrency} />
            </View>
            <View style={{ width: 92 }}>
              <CoreText
                style={[
                  styles.filterTitle,
                  { marginBottom: 12, color: "rgba(18, 18, 18, 0.75)" },
                ]}
                textTitle="Language"
                fontType={AssistantRegular}
              />
              <Picker items={Languages} isLanguage />
            </View>
          </View>
          <View style={{ alignSelf: "center" }}>
            <View style={styles.paymentMethodContainer}>
              {paymentMethod.map((item, index) => (
                <Image
                  key={index}
                  source={item.imgSource}
                  style={{
                    width: 42,
                    height: 27,
                    marginRight: 6,
                    resizeMode: "cover",
                  }}
                />
              ))}
            </View>
            <View style={styles.copyRightSection}>
              <CoreText
                textTitle="© 2021, dawn-theme-default Powered by Shopify"
                fontType={AssistantMedium}
                style={styles.copyRightText}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Footer;

const socialIcon = [
  {
    icon: "twitter",
  },
  {
    icon: "facebook-square",
  },
  {
    icon: "instagram",
  },
  {
    icon: require("../assets/images/TikTok.png"),
  },
  {
    icon: "youtube",
  },
];
const footerMenu = [
  {
    menu: "Quick links",
    submenu1: "Bags",
    submenu2: "Shoes",
    submenu3: "Lookbook",
  },
  {
    menu: "Info",
    submenu1: "About",
    submenu2: "Contact us",
    submenu3: "Shipping policy",
    submenu4: "Blog",
  },
];

const paymentMethod = [
  {
    imgSource: require("../assets/images/shopPay.png"),
  },
  {
    imgSource: require("../assets/images/visa.png"),
  },
  {
    imgSource: require("../assets/images/mastercard.png"),
  },
  {
    imgSource: require("../assets/images/applepay.png"),
  },
  {
    imgSource: require("../assets/images/googlepay.png"),
  },
  {
    imgSource: require("../assets/images/amex.png"),
  },
];
